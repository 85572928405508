import React, { useContext, useState, useEffect } from "react";
import { Card, CardHeader, Table, Container, Row, Spinner } from "reactstrap";
import ReactQuill from "react-quill";
import { AppContext } from "components/Context/ToastContext";
import "react-quill/dist/quill.snow.css";
import { checkAuth } from "utils/auth";
import { createCourse, editCourse, getCourse, postCourse } from "services/Api";
import { useHistory, useParams } from "react-router-dom";
import { ToastTypes } from "components/Toast/ToastHelper";
import { Field, useFormik, useFormikContext } from "formik";
import { courseSchema } from "utils/schemas";

const Course = (props) => {
  const [course, setCourse] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const { setLoader, showToast } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const redirect = useHistory();
  let { id } = useParams();

  const updateCourse = async (values) => {
    setLoader(true);

    var courseCopy = {
      ...values,
    };
    editCourse(id, courseCopy)
      .then((res) => {
        console.log(res.data);
        redirect.push(`/admin/courses`);
        showToast(ToastTypes.success, "Course added Sucessfully");
        setLoader(false);
      })
      .catch((error) => {
        checkAuth(error, redirect);
        setLoader(false);
        showToast(ToastTypes.error, error.response.data.message);
        console.log(error);
      });
  };
  const addCourse = async (values) => {
    setLoader(true);
    var courseCopy = {
      ...values,
    };
    createCourse(courseCopy)
      .then((res) => {
        console.log(res.data);
        showToast(ToastTypes.success, "Course added Sucessfully");
        setLoader(false);
        redirect.push(`/admin/courses`);
      })
      .catch((error) => {
        checkAuth(error, redirect);
        setLoader(false);
        showToast(ToastTypes.error, error.response.data.message);
        console.log(error);
      });
  };
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
    console.log("selected File", selectedFile);
  };
  const onDescriptionChange = (text) => {
    console.log("description ", text);
    var courseCopy = { ...course };

    courseCopy["description"] = text;
    setCourse(courseCopy);
  };
  const onActiveChange = (e) => {
    e.persist();
    if (course.active == null) course.active = 0;
    course.active = e.target.checked;
    setCourse({ ...course, active: e.target.checked ? 1 : 0 });
  };
  const getCourseDetails = () => {
    setLoader(true);
    getCourse(id)
      .then((res) => {
        console.log(res.data.data.course);
        setCourse(res.data.data.course);
        setLoader(false);
      })
      .catch((error) => {
        checkAuth(error, redirect);
        setLoader(false);
        showToast(ToastTypes.error, error.response.data.message);
        console.log(error);
      });
  };
  useEffect(() => {
    if (id != null) getCourseDetails();
  }, []);

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: course,
    validationSchema: courseSchema,
    onSubmit: (values, action) => {
      // This will be called when the data is VALID
      if (id != null) updateCourse(values);
      else addCourse(values);
      console.log("formik onSubmit values:", values);
    },
    onChange: (values) => {
      console.log("formik onChange values: ", values);
    },
  });
  useEffect(() => {
    console.log("formik error updated:", errors);
  }, [errors]);
  return (
    <>
      <Container>
        {loading ? (
          <Spinner />
        ) : (
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0"></CardHeader>
                <form onSubmit={handleSubmit}>
                  <div className="mx-4">
                    <div className="form-group col-md-7">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={values.name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {errors.name && touched.name ? (
                        <p className="form-error text-red">{errors.name}</p>
                      ) : null}
                    </div>

                    <div className="form-group col-md-7">
                      <label htmlFor="default_price">Price</label>
                      <input
                        type="number"
                        className="form-control"
                        id="default_price"
                        name="default_price"
                        value={values.default_price}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      {errors.default_price && touched.default_price ? (
                        <p className="form-error text-red">
                          {errors.default_price}
                        </p>
                      ) : null}
                    </div>

                    <div className="row mx-1">
                      <div className="form-group col-md-6">
                        <label htmlFor="exampleInputFile">Course Image</label>
                        <input
                          type="file"
                          className="form-control-file"
                          id="photo"
                          aria-describedby="fileHelp"
                          accept="image/*"
                          onChange={(event) =>
                            setFieldValue("photo", event.target.files[0])
                          }
                          onBlur={handleBlur}
                        />
                        {errors.photo && touched.photo ? (
                          <p className="form-error text-red">{errors.photo}</p>
                        ) : null}
                      </div>
                      {(values.photo || course.thumbnail) && (
                        <div className="col-md-6">
                          <img
                            className=" img-fluid"
                            alt="image"
                            src={
                              values.photo
                                ? URL.createObjectURL(values.photo)
                                : course.thumbnail
                            }
                            height="150"
                            width="150"
                          />
                        </div>
                      )}
                    </div>
                    <div className="form-group col-md-8 mb-4">
                      <label htmlFor="exampleInputFile">Description</label>

                      <ReactQuill
                        value={values.description}
                        onChange={(text) => setFieldValue("description", text)}
                        style={{ height: "100px", marginBlockEnd: "10px" }}
                      />

                      {errors.description ? (
                        <p className="form-error text-red">
                          {errors.description}
                        </p>
                      ) : null}
                    </div>
                    <div className="row">
                      <div className="form-group col-md-7">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="active"
                            checked={values.active}
                            onChange={(event) => {
                              console.log(
                                "active toggled",
                                event.target.checked
                              );
                              setFieldValue(
                                "active",
                                event.target.checked ? 1 : 0
                              );
                            }}
                            name="active"
                          />
                          <label
                            className="custom-control-label mt-2"
                            htmlFor="active"
                          >
                            Active
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8  text-center mt-5 mb-5 ">
                    <button type="submit" className="btn btn-primary">
                      {id == null ? "Create" : "Update"}
                    </button>
                  </div>
                </form>
              </Card>
            </div>
          </Row>
        )}
      </Container>
    </>
  );
};

export default Course;
