import checkIcon from "assets/img/icons/common/check.svg";
import errorIcon from "assets/img/icons/common/error.svg";
import infoIcon from "assets/img/icons/common/info.svg";
import warningIcon from "assets/img/icons/common/warning.svg";

export const ToastTypes = {
  success: {
    title: "Success",
    backgroundColor: "#5cb85c",
    icon: checkIcon,
  },
  error: {
    title: "Error",
    backgroundColor: "#d9534f",
    icon: errorIcon,
  },
  info: {
    title: "Info",
    backgroundColor: "#5bc0de",
    icon: infoIcon,
  },
  warning: {
    title: "Warning",
    backgroundColor: "#f0ad4e",
    icon: warningIcon,
  },
};

export function generateToast(toast, description) {
  toast.id = Math.floor(Math.random() * 101 + 1);
  toast.description = description;
  return toast;
}
