import React, { useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import Index from "pages/Index";
import axios from "axios";
import moment from "moment";
import { postNotificaton } from "services/Api";
import { useState, useEffect } from "react";
import { checkAuth } from "utils/auth";
import { useHistory } from "react-router-dom";
import { AppContext } from "components/Context/ToastContext";

export const NotificationModal = (props) => {
  const redirect = useHistory();
  const { showToast, setLoader } = useContext(AppContext);
  const [notification, setNotification] = useState({
    type: "general",
    category: "promotional",
  });

  const onChange = (e) => {
    setNotification({ ...notification, [e.target.name]: e.target.value });
    console.log(
      "notification changed",
      notification,
      e.target.value,
      e.target.name
    );
  };

  const onTypeChange = (e) => {
    setNotification({ ...notification, [e.target.name]: e.target.value });
    console.log("type changed", notification, e.target.value, e.target.name);
  };

  const postData = async (e) => {
    e.preventDefault();
    postNotificaton(notification)
      .then((res) => {
        console.log("User Notify responce", res.data.data);
        showToast(ToastTypes.success, "User Notification added Sucessfully");
        setNotification(null);
      })
      .catch((error) => {
        checkAuth(error, redirect);
        setLoader(false);
        showToast(ToastTypes.error, error.response.data.message);
        console.log(error);
      });
  };

  const options = ["Kishul Solutions", "Trade School"];
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.close}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            User Notifications
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="mb-3">
              <div className="row align-items-center pt-4 pb-3">
                <div className="col-md-3 ">
                  <label htmlFor="type" className="form-label">
                    Type
                  </label>
                </div>
                <div className="col-md-9">
                  <select
                    name="type"
                    className="form-select"
                    aria-label="Default select example"
                    required
                    onChange={onTypeChange}
                  >
                    <option value="general">General</option>
                    <option value="tagged">Batch</option>
                  </select>
                </div>
              </div>
            </div>
            {notification && notification.type == "general" ? (
              <div className="row align-items-center pt-4 pb-3">
                <div className="col-md-3 ">
                  <label htmlFor="category" className="form-label">
                    Category
                  </label>
                </div>

                <div className="col-md-9">
                  <select
                    name="category"
                    className="form-select form-select-lg mb-3"
                    aria-label=".form-select-lg example"
                    onChange={onChange}
                    defaultValue="promotional"
                  >
                    <option value="promotional">Promotional</option>
                    <option value="live class update">Live Class Update</option>
                    <option value="daily news">Daily News</option>
                    <option value="others">Others</option>
                  </select>
                </div>
              </div>
            ) : null}
            {notification && notification.type == "tagged" ? (
              <div className="row align-items-center pt-4 pb-3">
                <div className="col-md-3 ">
                  <label htmlFor="title" className="form-label">
                    Batch
                  </label>
                </div>
                <div className="col-md-9">
                  <select
                    className="form-select"
                    defaultValue="Kishul Solutions"
                    aria-label="Default select example"
                    onChange={onChange}
                    name="category"
                  >
                    {options.map((option, index) => (
                      <option value={option} key={index}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ) : null}
            <div className="row align-items-center pt-4 pb-3">
              <div className="col-md-3">
                <label htmlFor="title" className="form-label">
                  Title
                </label>
              </div>
              <div className="col-md-9">
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  name="title"
                  maxLength={80}
                  value={
                    notification == null || notification.title == null
                      ? ""
                      : notification.title
                  }
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="row  pt-4 pb-3">
              <div className="col-md-3">
                <label htmlFor="exampleInputPassword1" className="form-label">
                  Body
                </label>
              </div>

              <div className="col-md-9">
                <input
                  type="text"
                  className="form-control"
                  id="body"
                  maxLength="255"
                  name="body"
                  required
                  value={
                    notification == null || notification.body == null
                      ? ""
                      : notification.body
                  }
                  onChange={onChange}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={props.close}>
            Cancel
          </button>
          <button type="button" className="btn btn-primary" onClick={postData}>
            Send
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
