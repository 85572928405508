
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// reactstrap components

import GrantAccessModal from "./users/GrantAccessModal";
import UserList from "./users/UserList";
import UserModal from "./users/UserModal";
// import { Context } from "components/Context/ToastContext";

const Index = (props) => {
  let redirect = useHistory();

  const token = getToken();
  function getToken() {
    const userString = localStorage.getItem("user");
    const userObject = JSON.parse(userString);
    return userObject?.token;
  }
  if (token == null) {
    redirect.push("/auth/login");
  }

  function onGrantAcessModalClose() {
    setGrantUser(null);
  }

  return (
    <>
      <UserList />
    </>
  );
};
export default Index;
