import React from "react";
import { useMemo, useState, useEffect, useContext } from "react";
import { useTable, useSortBy } from "react-table";
import { AppContext } from "components/Context/ToastContext";
import { Card, CardHeader, Table, Container, Row, Spinner } from "reactstrap";
import { checkAuth } from "utils/auth";
import { useHistory } from "react-router-dom";
import { getExamSets } from "services/Api";
import moment from "moment/moment";

const ExamSetList = (props) => {
  const [data, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [student, setStudent] = useState();
  const { setLoader } = useContext(AppContext);

  const redirect = useHistory();
  const routeChange = () => {
    redirect.push(`/admin/exam-sets/new`);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoader(true);
    getExamSets()
      .then((res) => {
        setTableData(res.data.data.exam_sets);
        console.log(res.data.data.exam_sets);
        setLoader(false);
      })
      .catch((error) => {
        checkAuth(error, redirect);
        console.log(error);
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Examination",
        accessor: "name",
        // accessor: (d) => {
        //   return moment(d.created_at).local().format("DD-MM-YYYY hh:mm a");
        // },
      },
      {
        Header: "Subtext",
        accessor: "subtext",
      },
      {
        Header: "Exam Date",
        accessor: "exam_time",
      },

      {
        Header: "ACTIONS",
        Cell: (row) => (
          <div className="table-btn">
            <button
              className="btn btn-sm bg-success rounded-pill ts-btn text-white   mr-2"
              onClick={() => {
                redirect.push(`exam-sets/${row.row.original.id}/edit`);
                console.log(row.row.original.id);
              }}
            >
              Edit
            </button>

            <button
              className="btn bg-primary text-white rounded-pill btn-sm"
              onClick={() => {
                redirect.push(`exam-sets/${row.row.original.id}/users`);
                console.log(row.row.original.id);
              }}
            >
              Students
            </button>
          </div>
        ),
      },
    ],
    []
  );
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({ columns, data }, useSortBy);

  return (
    <>
      {/* <Header /> */}
      {/* Page content */}
      <Container fluid>
        {loading ? (
          <Spinner />
        ) : (
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <div className="row text-left">
                    <div className="col-md-4 col-xs-2">
                      <h3 className="mb-0">Exams</h3>
                    </div>
                    <div className="col text-right">
                      <button
                        className="btn bg-primary text-white btn-sm "
                        onClick={routeChange}
                      >
                        New Exams
                      </button>
                    </div>
                  </div>
                </CardHeader>

                <Table columns={columns} data={data} />
                <table {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            className={
                              column.isSorted
                                ? column.isSortedDesc
                                  ? "sort-desc"
                                  : "sort-asc"
                                : ""
                            }
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>

                  <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Card>
            </div>
          </Row>
        )}
      </Container>
    </>
  );
};

export default ExamSetList;
