import Login from "pages/login/Login.jsx";
import Index from "pages/Index.jsx";
import Notifications from "pages/notifications/Notifications.jsx";
import BatchList from "pages/batches/BatchList";
import Batch from "pages/batches/Batch";
import TransactionList from "pages/transaction/TransactionList";
import ScholarshipProfiles from "pages/scholarship_profiles/ScholarshipProfiles";
import Roles from "services/Roles";
import CouponsList from "pages/coupons/CouponsList";
import ExamSetUserList from "pages/exams/ExamSetUserList";
import ExamSet from "pages/exams/ExamSet";
import ExamSetList from "pages/exams/ExamSetList";
import CourseList from "pages/exams/courses/CourseList";
import Course from "pages/exams/courses/Course";
import ScholarshipDetails from "pages/scholarship_profiles/ScholarshipDetails";
import VideoList from "pages/exams/courses/VideoList";
import PaperList from "pages/papers/PaperList";
import PaperDetails from "pages/papers/PaperDetails";
var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 ",
    component: Index,
    layout: "/admin",
    display: true,
    permission: [Roles.ADMIN, Roles.STAFF],
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: "ni ni-bell-55 ",
    component: Notifications,
    layout: "/admin",
    display: false,
    permission: [Roles.ADMIN, Roles.STAFF],
  },

  {
    path: "/batches/:id/users",
    name: "Batch",
    icon: "ni ni-single-02 ",
    component: Batch,
    layout: "/admin",
    display: false,
    permission: [Roles.ADMIN, Roles.STAFF],
  },
  {
    path: "/batches",
    name: "Batches",
    icon: "ni ni-single-02 ",
    component: BatchList,
    layout: "/admin",
    display: false,
    permission: [Roles.ADMIN, Roles.STAFF],
  },
  {
    path: "/payments",
    name: "Payments",
    icon: "fab fa-google-wallet",
    component: TransactionList,
    layout: "/admin",
    display: true,
    permission: [Roles.ADMIN, Roles.STAFF],
  },
  {
    path: "/scholarship_profiles",
    name: "Scholarship Profiles",
    icon: "ni ni-paper-diploma",
    component: ScholarshipProfiles,
    layout: "/admin",
    display: true,
    permission: [Roles.ADMIN, Roles.STAFF],
  },
  {
    path: "/scholarship_details/:id",
    name: "Scholarship Details",
    icon: "ni ni-paper-diploma",
    component: ScholarshipDetails,
    layout: "/admin",
    display: false,
    permission: [Roles.ADMIN, Roles.STAFF],
  },
  {
    path: "/exam-sets/:id/users",
    name: "ExamSetUserList",
    icon: "fas fa-user-friends",
    component: ExamSetUserList,
    layout: "/admin",
    display: false,
    permission: [Roles.ADMIN, Roles.STAFF],
  },
  {
    path: "/exam-sets/:id/edit",
    name: "Exam Set",
    icon: "fa-regular fa-file",
    component: ExamSet,
    layout: "/admin",
    display: false,
    permission: [Roles.ADMIN, Roles.STAFF],
  },
  {
    path: "/exam-sets/new",
    name: "New Exam Set",
    icon: "fa-regular fa-file",
    component: ExamSet,
    layout: "/admin",
    display: false,
    permission: [Roles.ADMIN, Roles.STAFF],
  },
  {
    path: "/exam-sets",
    name: "Exam Sets",
    icon: "ni ni-single-copy-04",
    component: ExamSetList,
    layout: "/admin",
    display: true,
    permission: [Roles.ADMIN, Roles.STAFF],
  },
  {
    path: "/papers/:id",
    name: "Paper Details",
    icon: "ni ni-single-copy-04",
    component: PaperDetails,
    layout: "/admin",
    display: false,
    permission: [Roles.ADMIN, Roles.STAFF],
  },
  {
    path: "/papers",
    name: "Papers",
    icon: "ni ni-single-copy-04",
    component: PaperList,
    layout: "/admin",
    display: true,
    permission: [Roles.ADMIN, Roles.STAFF],
  },
  {
    path: "/coupons",
    name: "Coupons",
    icon: "ni ni-ungroup",
    component: CouponsList,
    layout: "/admin",
    display: true,
    permission: [Roles.ADMIN, Roles.STAFF],
  },

  {
    path: "/courses/new",
    name: "Courses",
    icon: "fa-solid fa-file",
    component: Course,
    layout: "/admin",
    display: false,
    permission: [Roles.ADMIN, Roles.STAFF],
  },
  {
    path: "/courses/:id/edit",
    name: "Courses",
    icon: "fa-solid fa-file",
    component: Course,
    layout: "/admin",
    display: false,
    permission: [Roles.ADMIN, Roles.STAFF],
  },
  {
    path: "/courses/:id/video/edit",
    name: "Videos",
    icon: "fa-solid fa-file",
    component: VideoList,
    layout: "/admin",
    display: false,
    permission: [Roles.ADMIN, Roles.STAFF],
  },
  {
    path: "/courses/:id/video",
    name: "Videos",
    icon: "fa-solid fa-file",
    component: VideoList,
    layout: "/admin",
    display: false,
    permission: [Roles.ADMIN, Roles.STAFF],
  },
  {
    path: "/courses",
    name: "Courses",
    icon: "fa-solid fa-list-ul",
    component: CourseList,
    layout: "/admin",
    display: true,
    permission: [Roles.ADMIN, Roles.STAFF],
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25",
    component: Login,
    layout: "/auth",
    display: false,
  },
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth",
  // },
];
export default routes;
