import React, { useContext, useEffect, useMemo, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { checkAuth } from "utils/auth";
import { getUserByBatchId } from "services/Api";
import { useSortBy, useTable } from "react-table";
import { Card, Table, Container, Row, Spinner, CardHeader } from "reactstrap";
import { useParams } from "react-router-dom";
import { AppContext } from "components/Context/ToastContext";

const Batch = () => {
  const [data, setData] = useState([]);
  const [batch, setBatch] = useState();
  const { setLoader } = useContext(AppContext);
  const redirect = useHistory();
  let { id } = useParams();

  const getData = async () => {
    setLoader(true);
    getUserByBatchId(id)
      .then((res) => {
        setBatch(res.data.data.batch);
        setData(res.data.data.batch.users);
        setLoader(false);
      })
      .catch((error) => {
        checkAuth(error, redirect);
        console.log(error);
      });
  };
  useEffect(() => {
    if (batch == null) getData(batch);
  }, [batch]);

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
    ],
    []
  );
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({ columns, data }, useSortBy);

  return (
    <>
      {batch != null ? (
        <Container fluid>
          <Row></Row>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <div className="row ">
                    <div className="col-10 text-center">
                      <span className="mb-0 ">
                        <b>#{batch.id}</b>
                      </span>
                      <span className="mb-0 ml-3">
                        <b>{batch.name}</b>
                      </span>
                    </div>
                    <div className="col-2 text-right ">
                      <button
                        className="btn bg-primary text-white btn-sm justify-content-end"
                        // onClick={isOpen}
                      >
                        New User
                      </button>
                    </div>
                  </div>
                </CardHeader>
                <Table
                  className="col offset-lg-1"
                  columns={columns}
                  data={data}
                />
                <div className="table-responsive">
                  <table
                    className="table table-sm"
                    {...getTableProps()}
                    style={{ overflowY: "hidden" }}
                  >
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                              className={
                                column.isSorted
                                  ? column.isSortedDesc
                                    ? "sort-desc"
                                    : "sort-asc"
                                  : ""
                              }
                            >
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                      {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              console.log("cell", cell);
                              return (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Card>
            </div>
          </Row>
        </Container>
      ) : (
        <span>Loading...</span>
      )}
    </>
  );
};

export default Batch;
