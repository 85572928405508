import { data } from "jquery";
import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";

import { Redirect, useHistory } from "react-router-dom"; // reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Label,
  Alert,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { login } from "services/Api";
import Roles from "services/Roles";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  let redirect = useHistory();

  // const [credentials, setCredentials] = useState({ email: "", password: "" });
  const handleSubmit = async (e) => {
    e.preventDefault();
    login(email, password)
      .then((response) => {
        try {
          console.log("Response login", response);
          localStorage.setItem("user", JSON.stringify(response.data.data.user));
          if (response.data.data.user.role == Roles.SUPERVISOR)
            redirect.push("/exam-sets");
          else redirect.push("/index");
        } catch (error) {
          console.log("login error", error);
          console.error(error);
          alert("Invalid Username or Password");
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Invalid Username or Password");
      });
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Sign in to your Account</small>
            </div>
            <Form role="form" onSubmit={handleSubmit}>
              <FormGroup className="mb-3">
                <label>Email</label>
                <InputGroup className="input-group-alternative">
                  <Input
                    // placeholder="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    name="email"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <label>Password</label>
                <InputGroup className="input-group-alternative">
                  <Input
                    // placeholder="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    name="password"
                  />
                </InputGroup>
              </FormGroup>

              <div>
                <Button color="primary" type="submit">
                  Login
                </Button>
                {/* {error?<Label>{error}</Label>:null}  */}
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Login;
