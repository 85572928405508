import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Container, Dropdown, Nav, Navbar, NavDropdown } from "react-bootstrap";
import brand_logo from "assets/img/brand/ts_icon.png";
import performLogout, { getLoggedUser } from "utils/auth";

const AdminNavbar = (props) => {
  const user = getLoggedUser();

  const redirect = useHistory();
  return (
    <>
      <Navbar
        expand="lg"
        bg="blue"
        variant="dark"
        style={{ padding: "10px 15px" }}
      >
        <Navbar.Text className="text-white" style={{ fontSize: "1rem" }}>
          {props.brandText}
        </Navbar.Text>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="justify-content-end flex-grow-1 pe-3">
            <NavDropdown
              style={{ fontSize: "1rem" }}
              title={<span>{(user && user.name) || ""} </span>}
              id="collasible-nav-dropdown"
            >
              {/* <Dropdown.ItemText>Welcome</Dropdown.ItemText>
              <NavDropdown.Divider /> */}
              <NavDropdown.Item onClick={(e) => performLogout(e, redirect)}>
                <i className="ni ni-user-run" />
                Log Out
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
