import React, { useRef } from "react";
import { useMemo, useState, useEffect, useContext } from "react";
import { Card, CardHeader, Table, Container, Row, Spinner } from "reactstrap";
import { AppContext } from "components/Context/ToastContext";
import { checkAuth } from "utils/auth";
import {
  getExamSetDetails,
  initExamSet,
  postExamSets,
  putExamSets,
} from "services/Api";
import { useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { courseSchema } from "utils/schemas";
import {
  generateId,
  generateToast,
  ToastTypes,
} from "components/Toast/ToastHelper";
import ReactQuill from "react-quill";

const ExamSet = () => {
  const { setLoader, showToast } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [examPaperList, setExamPaperList] = useState([]); // examPapers
  const [examSet, setExamSet] = useState({ duration: 10800 });
  const redirect = useHistory();
  const rulesRef = useRef(null);
  let { id } = useParams();
  useEffect(() => {
    if (id == null) fetchData();
    else getExamDetails();
  }, []);
  const getExamDetails = async () => {
    setLoader(true);
    getExamSetDetails(id)
      .then((res) => {
        console.log("exam details", res.data.data);
        setExamPaperList(res.data.data.exam_papers);
        setExamSet(res.data.data.exam_set);
        setLoader(false);
      })
      .catch((error) => {
        checkAuth(error, redirect);
        setLoader(false);
        showToast(ToastTypes.error, error.response.data.message);
        console.log(error);
      });
  };
  const fetchData = async () => {
    setLoader(true);
    initExamSet()
      .then((res) => {
        console.log("initExamSet", res.data.data.exam_papers);
        setExamPaperList(res.data.data.exam_papers);
        setLoader(false);
      })
      .catch((error) => {
        checkAuth(error, redirect);
        setLoader(false);
        showToast(ToastTypes.error, error.response.data.message);
        console.log(error);
      });
  };

  const onExamCheckClicked = (event, exam, id) => {
    console.log("onExamCheckClicked", event.target.checked, exam, id);
    var papers = [...examPaperList]; // cloning
    papers[id].checked = event.target.checked; // updating value
    setExamPaperList(papers); // setting state
  };

  const onChange = (e, exam, index) => {
    var papers = [...examPaperList]; // cloning
    papers[index].pivot[e.target.name] = e.target.value; // updating value
    setExamPaperList(papers); // setting statepivot
    console.log(
      "Exam set Changed",
      papers[index],
      e.target.value,
      e.target.name
    );
  };

  const onHandleChanged = (e) => {
    const examSetCopy = { ...examSet };
    examSetCopy[e.target.name] = e.target.value;
    setExamSet(examSetCopy);
  };
  const onRuleChange = (e) => {
    const examSetCopy = { ...examSet };
    examSetCopy["rules"] = rulesRef.current.value;
    setExamSet(examSetCopy);
  };

  const putExamSetData = (e) => {
    e.preventDefault();
    // setLoader(true);
    var exams = [...examPaperList].filter((exam) => exam.checked);
    var request = { ...examSet, exams, rules: rulesRef.current.value };
    console.log("putExamSetData", request);
    putExamSets(id, request)
      .then((res) => {
        console.log("Exam set put Data", res.data);
        showToast(ToastTypes.success, "Exam updated successfully");
        setLoader(false);
        redirect.push(`/admin/exam-sets`);
      })
      .catch((error) => {
        checkAuth(error, redirect);
        setLoader(false);
        showToast(ToastTypes.error, error.response.data.message);
        console.log(error);
      });
  };
  const postExamSetData = (e) => {
    e.preventDefault();
    // setLoader(true);
    var exams = [...examPaperList].filter((exam) => exam.checked);
    var request = { ...examSet, exams, rules: rulesRef.current.value };
    console.log("postExamSets", request);
    postExamSets(request)
      .then((res) => {
        console.log("Exam set Post Data", res.data);
        showToast(ToastTypes.success, "Exam added successfully");
        setLoader(false);
        redirect.push(`/admin/exam-sets/${res.data.data.exam_set.id}/users`);
      })
      .catch((error) => {
        checkAuth(error, redirect);
        setLoader(false);
        showToast(ToastTypes.error, error.response.data.message);
        console.log(error);
      });
  };
  const initialValues = {
    name: "",
    exam_time: "",
    positive_marks: "",
    negative_marks: "",
    duration: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: courseSchema,
      onSubmit: (values, action) => {
        console.log("exam-set values", values);
        action.resetForm();
      },
    });
  console.log("exam-set errors", errors);
  return (
    <>
      {/* <Header /> */}
      {/* Page content */}
      <Container>
        {loading ? (
          <Spinner />
        ) : (
          // <Row>
          <Card className="shadow">
            <div className="card-body">
              <form>
                <div className="row mt-5">
                  <div className="col-md-6 mb-4">
                    <h2 htmlFor="inputEmail4" className="form-label">
                      Examination:
                    </h2>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder="Title"
                      required
                      value={
                        examSet == null || examSet.name == null
                          ? ""
                          : examSet.name
                      }
                      onChange={onHandleChanged}
                    />
                    {errors.name && touched.name ? (
                      <p className="form-error">{errors.name}</p>
                    ) : null}
                  </div>
                  <div className="mb-4 col-md-3 form-group">
                    <label htmlFor="dtpickerdemo" className="form-label">
                      Select date/time:
                    </label>
                    <div className="input-group date" id="dtpickerdemo">
                      <input
                        type="datetime-local"
                        className="form-control"
                        required
                        name="exam_time"
                        onChange={onHandleChanged}
                        defaultValue={examSet.exam_time}
                      />
                      {errors.exam_time && touched.exam_time ? (
                        <p className="form-error">{errors.exam_time}</p>
                      ) : null}
                      <span className="input-group-addon">
                        <span className="glyphicon glyphicon-calendar"></span>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-">
                    <label htmlFor="duration" className="form-label small">
                      Duration (in seconds)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="duration"
                      name="duration"
                      onChange={onHandleChanged}
                      defaultValue={examSet.duration}
                    />
                    {errors.duration && touched.duration ? (
                      <p className="form-error">{errors.duration}</p>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-4">
                    <label htmlFor="subtext" className="form-label">
                      Subtext
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="subtext"
                      name="subtext"
                      placeholder="One line description"
                      required
                      value={
                        examSet == null || examSet.subtext == null
                          ? ""
                          : examSet.subtext
                      }
                      onChange={onHandleChanged}
                    />
                    {errors.subtext && touched.subtext ? (
                      <p className="form-error">{errors.subtext}</p>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9 mb-4">
                    <label htmlFor="subtext" className="form-label">
                      Exam Instructions / Rules:
                    </label>
                    <ReactQuill
                      ref={rulesRef}
                      value={examSet.rules}
                      onChange={() => onRuleChange()}
                      style={{ height: "200px", marginBlockEnd: "50px" }}
                    />
                    {errors.subtext && touched.subtext ? (
                      <p className="form-error">{errors.subtext}</p>
                    ) : null}
                  </div>
                </div>
                <h2 htmlFor="inputState" className="form-label mb-3">
                  Subjects:
                </h2>

                {examPaperList &&
                  examPaperList.map((exam, index) => (
                    <div
                      className="row"
                      style={{ paddingLeft: "15px" }}
                      key={index}
                    >
                      <div className="col-md-3 form-group">
                        <input
                          className="form-check-control"
                          type="checkbox"
                          key={index}
                          required
                          name="paper"
                          checked={exam.checked}
                          id={index}
                          onChange={(event) =>
                            onExamCheckClicked(event, exam, index)
                          }
                        />
                        <label
                          className="form-check-label mx-1"
                          htmlFor={index}
                        >
                          {exam.name}
                        </label>
                      </div>
                      {exam.checked && (
                        <>
                          <div className="col-md-2">
                            <label
                              htmlFor="inputZip"
                              className="form-label small"
                            >
                              No. of Questions
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="no_of_questions"
                              name="no_of_questions"
                              required
                              onChange={(event) => onChange(event, exam, index)}
                              defaultValue={exam.pivot.no_of_questions}
                            />
                            {errors.no_of_questions &&
                            touched.no_of_questions ? (
                              <p className="form-error">
                                {errors.no_of_questions}
                              </p>
                            ) : null}
                          </div>
                          <div className="col-md-2">
                            <label
                              htmlFor="passing_marks"
                              className="form-label small"
                            >
                              Passsing Marks
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="passing_marks"
                              required
                              name="passing_marks"
                              onChange={(event) => onChange(event, exam, index)}
                              defaultValue={exam.pivot.passing_marks}
                            />
                            {errors.passing_marks && touched.passing_marks ? (
                              <p className="form-error">
                                {errors.passing_marks}
                              </p>
                            ) : null}
                          </div>

                          <div className="col-md-2">
                            <label
                              htmlFor="positive_marks"
                              className="form-label small"
                            >
                              +ve marks per question
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              required
                              id="positive_marks"
                              name="positive_marks"
                              onChange={(event) => onChange(event, exam, index)}
                              defaultValue={exam.pivot.positive_marks}
                            />
                            {errors.positive_marks && touched.positive_marks ? (
                              <p className="form-error">
                                {errors.positive_marks}
                              </p>
                            ) : null}
                          </div>
                          <div className="col-md-2">
                            <label
                              htmlFor="negative_marks"
                              className="form-label small"
                            >
                              -ve marks per question
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              required
                              id="negative_marks"
                              name="negative_marks"
                              onChange={(event) => onChange(event, exam, index)}
                              defaultValue={exam.pivot.negative_marks}
                            />
                            {errors.negative_marks && touched.negative_marks ? (
                              <p className="form-error">
                                {errors.negative_marks}
                              </p>
                            ) : null}
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                <div className="col-12 text-right">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={id == null ? postExamSetData : putExamSetData}
                  >
                    {id == null ? "Save" : "Update"}
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => redirect.push(`/admin/exam-sets`)}
                  >
                    Back
                  </button>
                </div>
              </form>
            </div>
          </Card>
        )}
      </Container>
    </>
  );
};

export default ExamSet;
