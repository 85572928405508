import { utils, writeFile } from "xlsx";
import moment from "moment";

export default {
  exportToExcel(data) {
    let time = moment().format("DD-MM-YY_hh_mm_a");
    const fileName = "TradeSchool_" + time + ".xlsx";
    console.log("tableData", data, fileName);
    let list = [];
    data.forEach((entry) => {
      let row = {
        ID: entry["id"],
        Name: entry["name"],
        Email: entry["email"],
        Contact: entry["contact_no"],
        "Registeration Date": moment(
          entry["registered_on"],
          "YYYY-MM-DD hh:mm:ss"
        ).format("DD-MMM-YYYY"),
        University: entry["university"],
        Institute: entry["institute"],
        Purchased: entry["purchased"],
      };
      list.push(row);
    });

    console.log("list", list);
    const workBook = utils.book_new();
    let dataSheet = utils.json_to_sheet(list);
    var cols = [
      { wch: 7 },
      { wch: 18 },
      { wch: 30 },
      { wch: 12 },
      { wch: 15 },
      { wch: 20 },
      { wch: 45 },
      { wch: 45 },
    ];
    dataSheet["!cols"] = cols;
    utils.book_append_sheet(workBook, dataSheet, "Students");

    /* ============================= Write to File ================= */
    writeFile(workBook, fileName);
  },
};
