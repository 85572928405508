import React from "react";
import {
  generateId,
  generateToast,
  ToastTypes,
} from "components/Toast/ToastHelper";
import Toast from "components/Toast/Toast";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { checkAuth } from "utils/auth";
import { getCoupon, getCourses, putCoupon } from "services/Api";
import toast from "components/Toast/Toast.css";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "components/Context/ToastContext";
import { postCoupon } from "services/Api";
import moment from "moment";
import { useEffect } from "react";
const CouponModal = (props) => {
  const [courses, setCourses] = useState([]);
  const [combos, setCombos] = useState([]);
  const [coupon, setCoupon] = useState(null);
  const { showToast, setLoader } = useContext(AppContext);
  const [entities, setEntities] = useState([]);
  let redirect = useHistory();
  useEffect(() => {
    console.log("Coupon Modal useEffect", props.coupon);
    setCoupon(props.coupon);
    if (props.coupon != null && props.coupon.id != null)
      apiGetCoupon(props.coupon.id);
    if (courses == null || courses.length == 0) coursesApi();
  }, [props.coupon]);

  const apiGetCoupon = async (id) => {
    setLoader(true);
    getCoupon(id)
      .then((res) => {
        console.log("Coupon Modal Coupon ", res.data.data);
        setCoupon(res.data.data.coupon);
        setEntities(res.data.data.coupon.entities);
        // TODO check the courses & combos
        courses.forEach(course => {
        });
        setLoader(false);
      })
      .catch((error) => {
        checkAuth(error, redirect);
        console.log(error);
        setLoader(false);
      });
  };

  const coursesApi = async () => {
    setLoader(true);
    getCourses()
      .then((res) => {
        console.log("Grant access Modal Courses ", res.data.data);
        setCourses(res.data.data.courses);
        setCombos(res.data.data.combos);
        setLoader(false);
      })
      .catch((error) => {
        checkAuth(error, redirect);
        console.log(error);
      });
  };
  const isAdd = () => {
    return coupon == null || coupon.id == null;
  };

  const onChange = (e) => {
    setCoupon({ ...coupon, [e.target.name]: e.target.value });
    console.log("New Coupon Added", coupon, e.target.value, e.target.name);
  };
  const dateFromDateString = (dateString) => {
    return moment(new Date(dateString)).format("YYYY-MM-DDTHH:mm:ss");
  };

  const onCheckboxClick = (e, type, id) => {
    e.persist();
    var entitiesCopy = [...entities];
    if (e.target.checked) {
      // add to the list
      var entity = {};
      entity.entity_type = type; // 1 : combo , 0 : courses
      entity.entity_id = id; // 1 : student , 0 : others
      entitiesCopy.push(entity);
    } else {
      // remove from the list
      entitiesCopy.forEach((entity, index, array) => {
        if (entity.entity_id == id && entities.entity_type == type)
          array.splice(index, 1);
      });
    }
    setEntities(entitiesCopy);
    console.log("id changed ", type, id, entity, entities);
  };
  const onCancelClicked = () => {
    props.close(false);
  };
  const postData = async () => {
    const couponCopy = { ...coupon, entities: [...entities] };
    console.log("post data ", couponCopy);
    postCoupon(couponCopy)
      .then((res) => {
        console.log("Grant access Modal Courses ", res.data.data);
        setCoupon({ active: false });
        showToast(ToastTypes.success, "Coupon added Sucessfully");
        props.close(true);
      })
      .catch((error) => {
        checkAuth(error, redirect);
        setLoader(false);
        showToast(ToastTypes.error, error.response.data.message);
        console.log(error);
      });
  };

  const putData = async () => {
    const couponCopy = { ...coupon, entities: [...entities] };
    console.log("post data ", couponCopy);
    putCoupon(couponCopy.id, couponCopy)
      .then((res) => {
        setCoupon({ active: false });
        showToast(ToastTypes.success, "Coupon updated Successfully");
        props.close(true);
      })
      .catch((error) => {
        checkAuth(error, redirect);
        setLoader(false);
        showToast(ToastTypes.error, error.response.data.message);
        console.log(error);
      });
  };
  return (
    <>
      <Modal
        show={props.show}
        onHide={() => props.close(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h3>New Coupon</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="row g-3">
            <div className="col-md-6">
              <label htmlFor="inputEmail4" className="form-label">
                Code
              </label>
              <input
                type="text"
                className="form-control"
                id="code"
                name="code"
                required
                onChange={onChange}
                value={coupon == null || coupon.code == null ? "" : coupon.code}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="inputPassword4" className="form-label">
                Description
              </label>
              <input
                type="text"
                className="form-control"
                id="description"
                name="description"
                onChange={onChange}
                value={
                  coupon == null || coupon.description == null
                    ? ""
                    : coupon.description
                }
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="inputState" className="form-label">
                Type
              </label>
              <select
                id="type"
                type="text"
                className="form-select form-control"
                name="type"
                required
                onChange={onChange}
              >
                <option>Choose...</option>
                <option value="0">Percentage</option>
                <option value="1">Fixed</option>
              </select>
            </div>
            <div className="col-md-6">
              <label htmlFor="inputCity" className="form-label">
                Amount / Percentage
              </label>
              <input
                type="number"
                className="form-control"
                id="amount"
                name="amount"
                required
                value={
                  coupon == null || coupon.amount == null ? "" : coupon.amount
                }
                onChange={onChange}
              />
            </div>
            {courses.length > 0 ? (
              <>
                {/* <div className="form-row"> */}
                <div className="form-group col-md-6">
                  <label
                    htmlFor="course"
                    className="fs-20"
                    style={{ fontWeight: "bold" }}
                  >
                    Courses
                  </label>
                  {courses.map((course) => {
                    let htmlId = "0_" + course.id;
                    return (
                      <>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="entity_id"
                            id={htmlId}
                            defaultChecked={courses.checked || false}
                            onChange={(e) => onCheckboxClick(e, 0, course.id)}
                          />
                          <label className="form-check-label" htmlFor={htmlId}>
                            {course.name}
                          </label>
                        </div>
                      </>
                    );
                  })}
                </div>
                {/* </div> */}
                {/* <div className="form-row"> */}
                <div className="form-group col-md-6">
                  <label htmlFor="course" style={{ fontWeight: "bold" }}>
                    Combo
                  </label>
                  {combos.map((combo, idx) => {
                    let htmlId = "1_" + combo.id;
                    return (
                      <>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="entity_id"
                            defaultChecked={
                              (entities.entity_type === 1 &&
                                entities.entity_id === 0) ||
                              false
                            }
                            onChange={(e) => onCheckboxClick(e, 1, combo.id)}
                            id={htmlId}
                          />
                          <label className="form-check-label" htmlFor={htmlId}>
                            {combo.name}
                          </label>
                        </div>
                      </>
                    );
                  })}
                </div>
                {/* </div> */}
              </>
            ) : (
              <span>Loading....</span>
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => onCancelClicked()}
          >
            Cancel
          </button>
          <Button
            className="btn btn-primary success"
            onClick={() => {
              isAdd() ? postData() : putData();
            }}
          >
            {isAdd() ? "Save" : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CouponModal;
