import {
  generateId,
  generateToast,
  ToastTypes,
} from "components/Toast/ToastHelper";
import Toast from "components/Toast/Toast";
import React from "react";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { postBatches } from "services/Api";
import { checkAuth } from "utils/auth";
import toast from "components/Toast/Toast.css";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "components/Context/ToastContext";

const NewBatchModal = (props) => {
  const [batches, setBatches] = useState({ active: false });
  const { showToast, setLoader } = useContext(AppContext);
  let redirect = useHistory();

  const onActiveChange = (e) => {
    e.persist();
    if (batches.active == null) batches.active = {};
    batches.active = e.target.checked;
    setBatches({ ...batches, [e.target.name]: e.target.checked });
    console.log("New batch Added", batches, e.target.value, e.target.name);
  };

  const onChange = (e) => {
    setBatches({ ...batches, [e.target.name]: e.target.value });
    console.log("New batch Added", batches, e.target.value, e.target.name);
  };

  const postData = async () => {
    // e.preventDefault();

    postBatches(batches)
      .then((res) => {
        setBatches({ active: false });
        showToast(ToastTypes.success, "Batch added Sucessfully");
        props.close();
      })
      .catch((error) => {
        checkAuth(error, redirect);
        setLoader(false);
        showToast(ToastTypes.error, error.response.data.message);
        console.log(error);
      });
  };

  const onCancelClicked = () => {
    props.close();
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.close}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
           <h3>New Batch</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-row">
              <div className="form-group col-8">
                <label htmlFor="inputUniversity">Name</label>
                <div className="custom-control custom-radio custom-control-inline px-0">
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Enter Name"
                      name="name"
                      onChange={onChange}
                      value={
                        batches == null || batches.name == null
                          ? ""
                          : batches.name
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-4">
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customSwitch2"
                    defaultChecked={(batches && batches.active === 1) || false}
                    onChange={onActiveChange}
                    name="activeswitch"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customSwitch2"
                  >
                    Active
                  </label>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => onCancelClicked()}
          >
            Cancel
          </button>
          <Button
            className="btn btn-primary success"
            onClick={() => postData()}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NewBatchModal;
