import React, { useState } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import AdminFooter from "components/Footers/AdminFooter.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import routes from "routes.jsx";
import Toast from "components/Toast/Toast";
import { AppContext } from "components/Context/ToastContext";
import { generateToast } from "components/Toast/ToastHelper";
import Loader from "components/spinner/Loader";
import "components/css/App.css";
import { getAllowedRoutes, isLoggedIn } from "utils/auth";

const Admin = (props) => {
  let allowedRoutes = [];

  if (isLoggedIn()) {
    allowedRoutes = getAllowedRoutes(routes);
  } else {
    return <Redirect to="/auth/login" />;
  }

  const [toastList, setToastList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const showToast = (toastType, description) => {
    console.log("showToast", toastType, description);

    var toast = generateToast(toastType, description);
    setToastList([...toastList, toast]);
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <>
      <Loader show={loader} />
      <AppContext.Provider
        value={{ toastList, showToast, setLoader, sidebarOpen, setSidebarOpen }}
      >
        <div
          className={`content ${
            sidebarOpen ? "content--right" : "content--left"
          }`}
        >
          <div className="main-content" ref={mainContent}>
            <AdminNavbar
              {...props}
              brandText={getBrandText(props.location.pathname)}
            />
            <Switch>
              {getRoutes(allowedRoutes)}
              <Redirect from="*" to="/admin/index" />
            </Switch>
          </div>
        </div>
        <Sidebar
          {...props}
          routes={allowedRoutes}
          logo={{
            innerLink: "/admin/index",
            imgAlt: "TradeSchool",
          }}
        />
      </AppContext.Provider>
      <Toast toastList={toastList} position="top-right" autoDeleteTime={1500} />
    </>
  );
};

export default Admin;
