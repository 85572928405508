import axios from "axios";
import { getLoggedUser, getLoggedUserToken } from "utils/auth";
const url = process.env.REACT_APP_API_URL;

export const login = async (email, password) => {
  return await axios.post(
    url + `login`,
    {
      email,
      password,
    },
    {
      headers: {
        "content-Type": "application/json",
      },
    }
  );
};

export const getUsers = async (data) => {
  return await axios.get(url + `dashboard/summary`, {
    headers: getAuthHeader(),
    params: data,
  });
};

export const getUserById = async (id) => {
  return await axios.get(url + `users/` + id, {
    headers: getAuthHeader(),
  });
};

export const getNotifications = async () => {
  return await axios.get(url + `notifications`, {
    headers: getAuthHeader(),
  });
};

export const postNotificaton = async (data) => {
  return await axios.post(url + `notify`, data, {
   headers: getAuthHeader(),
  });
};
export const getCourses = async () => {
  return await axios.get(url + `courses`, {
    headers: getAuthHeader(),
  });
};

export const postGrantAccess = async (id, data) => {
  return await axios.post(url + `users/${id}/grant`, data, {
    headers: getAuthHeader(),
  });
};

export const getBatches = async () => {
  return await axios.get(url + `batches`, {
    headers: getAuthHeader(),
  });
};

export const getUserByBatchId = async (id) => {
  return await axios.get(url + `batches/${id}/users`, {
    headers: getAuthHeader(),
  });
};

export const postBatches = async (data) => {
  const token = getLoggedUserToken();
  return await axios.post(url + `batches`, data, {
    headers: getAuthHeader(),
  });
};

export const getTranscations = async () => {
  return await axios.get(url + `transactions`, {
    headers: getAuthHeader(),
  });
};

export const getScholarship = async () => {
  return await axios.get(url + `scholarship-profiles`, {
    headers: getAuthHeader(),
  });
};
export const getScholarshipDetails = async (id) => {
  return await axios.get(url + `scholarship-profiles/${id}`, {
    headers: getAuthHeader(),
  });
};

function getAuthHeader() {
  const token = getLoggedUserToken();
  return { Authorization: `Bearer ${token}` };
}

export const getExamSets = async () => {
  return await axios.get(url+"exam-sets", {
    headers: getAuthHeader(),
  });
};
export const getExamSetDetails = async (id) => {
  return await axios.get(url+`exam-sets/${id}`, {
    headers: getAuthHeader(),
  });
};
export const initExamSet = async () => {
  return await axios.get(url+`exam-sets/init`, {
    headers: getAuthHeader(),
  });
};

export const getStudents = async (id) => {
  return await axios.get(url + `exam-sets/${id}/users`, {
    headers: getAuthHeader(),
  });
};

export const postExamSets = async (data) => {
  return await axios.post(url + `exam-sets`, data, {
    headers: getAuthHeader(),
  });
};

export const putExamSets = async (id,data) => {
  return await axios.put(url + `exam-sets/${id}`, data, {
    headers: getAuthHeader(),
  });
};

export const getCoupons = async () => {
  return await axios.get(url+ `coupons`, {
    headers: getAuthHeader(),
});
};

export const getCoupon = async (id) => {
  return await axios.get(url+ `coupons/${id}`, {
    headers: getAuthHeader(),
});
};

export const postCoupon = async (data) => {
  return await axios.post(url + `coupons`, data, {
    headers: getAuthHeader(),
  });
};

export const putCoupon = async (id, data) => {
  return await axios.put(url + `coupons/${id}`, data, {
    headers: getAuthHeader(),
  });
};

export const postUserId = async (data, id) => {
  return await axios.post(url + `exam-sets/${id}/users`, data, {
   headers: getAuthHeader(),
  });
};
export const editCourse = async (id,data) => {
  return await axios.post(url+`courses/${id}`  , data,{
    headers: getMultipathAuthHeader(),   
  } );
};
export const createCourse = async (data) => {
  return await axios.post(url+`courses`  , data,{
    headers: getMultipathAuthHeader(),   
  } );
};
function getMultipathAuthHeader() {
  const token = getLoggedUserToken();
  return { Authorization: `Bearer ${token}`,'content-type': 'multipart/form-data'  };
}

export const getCourse = async (id) => {
  return await axios.get(url + `courses/${id}`, {
    headers: getAuthHeader(),
  });
};
export const getVideoList = async (id) => {
  return await axios.get(url + `courses/${id}/videos`, {
    headers: getAuthHeader(),
  });
};
export const postVideoDetails =async(id,data)=>{
  return await axios.post(url+`courses/${id}/videos`, data, {
      headers: getAuthHeader(),
    }); 
}
export const getPapers = async (id) => {
  return await axios.get(url + `papers`, {
    headers: getAuthHeader(),
  });
};
export const postPaper = async (data) => {
  return await axios.post(url + `papers`, data, {
    headers: getAuthHeader(),
  });
};
export const putPaper = async (id,data) => {
  return await axios.put(url + `papers/${id}`, data, {
    headers: getAuthHeader(),
  });
};

export const getPaperDetails = async (id) => {
  return await axios.get(url + `papers/${id}/questions`, {
    headers: getAuthHeader(),
  });
};

export const postQuestion = async (id, data) => {
  return await axios.post(url + `papers/${id}/questions`, data, {
    headers: getMultipathAuthHeader(),
  });
};


export const putQuestion = async (paperId, questionId, data) => {
  return await axios.post(url + `papers/${paperId}/questions/${questionId}`, data, {
    headers: getMultipathAuthHeader(),
  });
};


export const getQuestion = async (paperId, questionId) => {
  return await axios.get(url + `papers/${paperId}/questions/${questionId}`, {
    headers: getAuthHeader(),
  });
};
