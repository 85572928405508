import React from "react";
import {
  generateId,
  generateToast,
  ToastTypes,
} from "components/Toast/ToastHelper";
import Toast from "components/Toast/Toast";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { checkAuth } from "utils/auth";
import toast from "components/Toast/Toast.css";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "components/Context/ToastContext";
import moment from "moment";
import { useEffect } from "react";
import { postPaper, putPaper } from "services/Api";

const PaperModal = (props) => {
  const [paper, setPaper] = useState(null);
  const { showToast, setLoader } = useContext(AppContext);
  let redirect = useHistory();
  const onCancelClicked = () => {
    props.close(null);
  };

  useEffect(() => {
    setPaper(props.paper);
  }, []);
  const handleChange = (e) => {
    setPaper({ ...paper, [e.target.name]: e.target.value });
    console.log("Paper Name Changed", paper, e.target.value, e.target.name);
  };
  const putData = async () => {
    setLoader(true);
    putPaper(paper.id, paper)
      .then((res) => {
        console.log("Paper Modal Name", res.data.data);
        showToast(ToastTypes.success, "Paper Name updated Sucessfully");
        props.close(res.data.data.paper);
        setLoader(false);
      })
      .catch((error) => {
        checkAuth(error, redirect);
        showToast(ToastTypes.error, error.response.data.message);
        console.log(error);
        setLoader(false);
      });
  };

  const postData = async () => {
    setLoader(true);
    postPaper(paper)
      .then((res) => {
        console.log("Paper Modal Name", res.data.data);
        showToast(ToastTypes.success, "Paper Name Added Sucessfully");
        props.close(paper);
        setLoader(false);
      })
      .catch((error) => {
        checkAuth(error, redirect);
        showToast(ToastTypes.error, error.response.data.message);
        console.log(error);
        setLoader(false);
      });
  };

  const isAdd = () => {
    return paper == null || paper.id == null;
  };
  return (
    <>
      <Modal
        show={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h3>
              {paper == null || paper.name == null ? "New Paper" : paper.name}
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="row g-3">
            <div className="col-md-6">
              <label htmlFor="inputEmail4" className="form-label">
                Paper Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                required
                onChange={handleChange}
                value={paper == null || paper.name == null ? "" : paper.name}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            onClick={() => (isAdd() ? postData() : putData())}
          >
            {isAdd() ? "Add" : "Update"}
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => onCancelClicked()}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PaperModal;
