import React from "react";
import { useMemo, useState, useEffect, useContext } from "react";
import { useTable, useSortBy } from "react-table";
import { AppContext } from "components/Context/ToastContext";
import { Card, CardHeader, Table, Container, Row, Spinner } from "reactstrap";
import { checkAuth } from "utils/auth";
import { useHistory, useParams } from "react-router-dom";
import { editCourse, getCourse, getVideoList } from "services/Api";
import NewVideoModal from "./NewVideoModal";
import { courseSchema } from "utils/schemas";
const VideoList = () => {
  const [videoList, setVideoList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setLoader } = useContext(AppContext);
  const redirect = useHistory();
  let { id } = useParams();

  useEffect(() => {
    if (id != null) fetchData();
  }, []);
  const isOpen = () => {
    setShowModal(true);
  };
  const isClose = () => {
    setShowModal(false);
    fetchData();
  };
  const fetchData = async () => {
    setLoader(true);
    getVideoList(id)
      .then((res) => {
        setVideoList(res.data.data.videos);
        console.log("Video List ", res.data.data.videos);
        setLoader(false);
      })
      .catch((error) => {
        checkAuth(error, redirect);
        console.log(error);
      });
  };
  const updateCourse = async (values) => {
    setLoader(true);

    var courseCopy = {
      ...values,
    };

    // courseCopy["photo"] = selectedFile;
    editCourse(id, courseCopy)
      .then((res) => {
        console.log(res.data);
        redirect.push(`/admin/courses`);
        showToast(ToastTypes.success, "Course added Sucessfully");
        setLoader(false);
      })
      .catch((error) => {
        checkAuth(error, redirect);
        setLoader(false);
        showToast(ToastTypes.error, error.response.data.message);
        console.log(error);
      });
  };
  const columns = useMemo(
    () => [
      {
        Header: "Subtext",
        accessor: "name",
      },
      {
        Header: "Title",
        accessor: "description",
      },
      {
        Header: "Thumbnail",
        Cell: (row) =>
          row.row.original.thumbnail ? (
            <div>
              <img
                className="img-fluid"
                src={row.row.original.thumbnail}
                width={50}
                height={50}
                alt="course-images"
              />
            </div>
          ) : null,
      },
      {
        Header: "Actions",
        Cell: (row) => (
          <div className="table-btn">
            {/* <button
              className="btn btn-sm bg-success rounded-pill ts-btn text-white   mr-2"
              onClick={() => {
                redirect.push(`/admin/courses/${row.row.original.id}/video/edit`);
                console.log(row);
              }}
            >
              Edit
            </button> */}
          </div>
        ),
      },
    ],
    []
  );
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({ columns, data: videoList }, useSortBy);
  return (
    <>
      {/* <Header /> */}
      {/* Page content */}
      <Container fluid>
        {loading ? (
          <Spinner />
        ) : (
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <div className="row text-left">
                    <div className="col text-right">
                      <button
                        className="btn bg-primary text-white btn-sm "
                        onClick={isOpen}
                      >
                        New Video
                      </button>
                    </div>
                  </div>
                </CardHeader>

                <Table columns={columns} data={videoList} />
                <table {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            className={
                              column.isSorted
                                ? column.isSortedDesc
                                  ? "sort-desc"
                                  : "sort-asc"
                                : ""
                            }
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>

                  <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Card>
            </div>
          </Row>
        )}
      </Container>
      <NewVideoModal show={showModal} close={isClose} id={id} />
    </>
  );
};

export default VideoList;
