import { intersection } from "lodash";

const performLogout = (e, redirect) => {
  if (e != null) e.preventDefault();
  localStorage.removeItem("user");
  localStorage.clear();
  redirect.push("/auth/login");
};

export const checkAuth = (error, redirect) => {
  if (error.response && error.response.status == 401) {
    // logout
    performLogout(null, redirect);
    return false; // user not authorized
  }
  return true;
};

export default performLogout;

export function isArrayWithLength(arr) {
  return Array.isArray(arr) && arr.length;
}

export function getAllowedRoutes(routes) {
  const user = getLoggedUser();
  return routes.filter(({ permission }) => {
    if (!permission) return true;
    else if (!isArrayWithLength(permission)) return true;
    else return intersection(permission, [user && user.role]).length;
  });
}

export function isLoggedIn() {
  return getLoggedUser() != null;
}

export function getLoggedUser() {
  return JSON.parse(localStorage.getItem("user"));
}

export function getLoggedUserToken() {
  var user = getLoggedUser();
  if (user != null) return user.token;
  return null;
}
