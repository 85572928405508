import React, { useState, useEffect, useRef, useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import Index from "pages/Index";
import axios from "axios";
import moment from "moment";
import { getUserById } from "services/Api";
import { useHistory } from "react-router-dom";
import { checkAuth } from "utils/auth";
import { AppContext } from "components/Context/ToastContext";
import { ToastTypes } from "components/Toast/ToastHelper";

const UserModal = (props) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const { showToast, setLoader } = useContext(AppContext);
  const redirect = useHistory();
  const readOnly = true;
  const onModalClose = () => {
    setUser(null);
    props.close();
  };
  const fetchData = async () => {
    setLoading(true);
    getUserById(props.user.id)
      .then((res) => {
        setUser(res.data.data.user);
        setLoading(false);
      })
      .catch((error) => {
        checkAuth(error, redirect);
        console.log(error);
      });
  };
  useEffect(() => {
    if (props.user != null) fetchData(props.user.id);
  }, [props.user]);

  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const dateFromDateString = (dateString) => {
    return moment(new Date(dateString)).format("YYYY-MM-DDT00:00:00.000");
  };
  const onUniversityChange = (e) => {
    e.persist();
    if (!user.profile) user.profile = {};
    user.profile.university = e.target.value;
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const onInstituteChange = (e) => {
    e.persist();
    if (!user.profile) user.profile = {};
    user.profile.institute = e.target.value;
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const onStreamChange = (e) => {
    e.persist();
    if (!user.profile) user.profile = {};
    user.profile.stream = e.target.value;
    setUser({ ...user });
  };
  const dateForPicker = (dateString) => {
    return moment(new Date(dateString)).format("YYYY-MM-DD");
  };
  const onTypeChange = (e) => {
    e.persist();
    if (user.profile == null) user.profile = {};
    user.profile.type = parseInt(e.target.value); // 1 : student , 0 : others
    setUser({ ...user, [e.target.name]: e.target.checked });
  };
  const onGenderChange = (e) => {
    e.persist();
    if (user.profile == null) user.profile = {};
    user.profile.gender = parseInt(e.target.value);
    setUser({ ...user, [e.target.name]: e.target.checked });
  };
  const onDateChange = (e) => {
    e.persist();
    if (user.profile == null) user.profile = {};
    user.profile.dob = e.target.value;
    setUser({ ...user, [e.target.name]: dateFromDateString(e.target.value) });
  };
  const onActiveChange = (e) => {
    e.persist();
    if (user.active == null) user.active = {};
    user.active = e.target.checked;
    setUser({ ...user, [e.target.name]: e.target.checked });
  };

  const updateUser = async (e) => {
    setLoader(true);
    e.preventDefault();
    await axios
      .put(`http://tsdev.kishulsolutions.in/api/admin/users/` + user.id)
      .then((res) => {
        setUser(res.data.data.user);
        showToast(ToastTypes.success, "Update user Sucessfully");
        console.log("Update user", res);
        setLoader(false);
      })
      .catch((error) => {
        console.log("Update User details error");
        setLoader(false);
        showToast(ToastTypes.error, error.response.data.message);
        console.log(error);
      });
  };
  return (
    <>
      <Modal
        show={props.user != null}
        onHide={props.close}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {user != null ? user.name : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {user != null ? (
            <form>
              <div className="form-row">
                <div className="form-group col-md-2">
                  <label htmlFor="inputEmail4">Id</label>
                  <input
                    type="id"
                    name="id"
                    value={user.id || 0}
                    readOnly={true}
                    className="form-control"
                  />
                </div>
                <div className="form-group col-md-10">
                  <label htmlFor="inputName">Name</label>
                  <input
                    type="name"
                    placeholder="Enter User Name"
                    className="form-control"
                    id="name"
                    name="name"
                    value={user.name || ""}
                    onChange={onChange}
                    readOnly={readOnly}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="inputemail">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    placeholder="Enter User Email"
                    onChange={onChange}
                    value={user.email || ""}
                    name="email"
                    readOnly={readOnly}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="inputcontactno">Contact No</label>
                  <input
                    type="text"
                    className="form-control"
                    id="conatctno"
                    placeholder="Enter User Contact No"
                    onChange={onChange}
                    value={user.contact_no || ""}
                    name="contact_no"
                    readOnly={readOnly}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-3">
                  <label htmlFor="inputcreatedat">Created At</label>
                  <input
                    type="text"
                    value={moment(new Date(user.created_at)).format(
                      "D MMMM YYYY, h:mm:ss a"
                    )}
                    className="form-control"
                    id="created_at"
                    readOnly={true}
                    name="date"
                  />
                </div>

                <div className="form-group col-md-5 text-center">
                  <label htmlFor="inputProfileType ">Profile Type</label>
                  <br />
                  <div className="custom-control custom-radio custom-control-inline px-0  ">
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        id="profile_student"
                        value={1}
                        className="custom-control-input"
                        checked={
                          (user.profile && user.profile.type == 1) || false
                        }
                        onChange={onTypeChange}
                        disabled={readOnly}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="profile_student"
                      >
                        Student
                      </label>
                    </div>
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="profile_others"
                        value={0}
                        disabled={readOnly}
                        checked={
                          (user.profile && user.profile.type == 0) || false
                        }
                        className="custom-control-input"
                        onChange={onTypeChange}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="profile_others"
                      >
                        Others
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group col-md-4">
                  <label htmlFor="inputInstitute">Date Of Birth</label>
                  <input
                    type="date"
                    className="form-control"
                    id="inputAddress2"
                    readOnly={readOnly}
                    value={
                      user.profile && user.profile.dob
                        ? dateForPicker(user.profile.dob)
                        : ""
                    }
                    placeholder={
                      user.profile && user.profile.dob
                        ? dateForPicker(user.profile.dob)
                        : "dd/mm/yyyy"
                    }
                    onChange={onDateChange}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="inputUniversity"> University</label>
                  <input
                    type="text"
                    className="form-control"
                    id="university"
                    name="university"
                    readOnly={readOnly}
                    placeholder="Enter User University"
                    onChange={onUniversityChange}
                    value={
                      user.profile && user.profile.university
                        ? user.profile.university
                        : ""
                    }
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="inputInstitute">Institute</label>
                  <input
                    type="text"
                    className="form-control"
                    id="institute"
                    name="institute"
                    readOnly={readOnly}
                    placeholder="Enter User Institute"
                    onChange={onInstituteChange}
                    value={
                      user.profile && user.profile.institute
                        ? user.profile.institute
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-3">
                  <label htmlFor="inputUniversity">Stream</label>
                  <input
                    type="text"
                    className="form-control"
                    id="stream"
                    placeholder="Enter Stream"
                    name="stream"
                    readOnly={readOnly}
                    onChange={onStreamChange}
                    value={
                      user.profile && user.profile.stream
                        ? user.profile.stream
                        : ""
                    }
                  />
                </div>
                <div className="form-group col-md-9 text-center">
                  <label htmlFor="inputProfileType">Gender</label>
                  <div className="custom-control custom-radio ">
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        id="male"
                        className="custom-control-input"
                        value={0}
                        disabled={readOnly}
                        onChange={onGenderChange}
                        checked={
                          (user.profile != null && user.profile.gender == 0) ||
                          false
                        }
                      />
                      <label className="custom-control-label" htmlFor="male">
                        Male
                      </label>
                    </div>

                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        id="female"
                        className="custom-control-input"
                        value={1}
                        disabled={readOnly}
                        onChange={onGenderChange}
                        checked={
                          (user.profile != null && user.profile.gender == 1) ||
                          false
                        }
                      />
                      <label className="custom-control-label" htmlFor="female">
                        Female
                      </label>
                    </div>
                    <div className="custom-control custom-radio custom-control-inline ">
                      <input
                        type="radio"
                        id="others"
                        className="custom-control-input"
                        value={2}
                        disabled={readOnly}
                        onChange={onGenderChange}
                        checked={
                          (user.profile != null && user.profile.gender == 2) ||
                          false
                        }
                      />
                      <label className="custom-control-label " htmlFor="others">
                        Others
                      </label>
                    </div>
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        id="notselected"
                        className="custom-control-input"
                        value={-1}
                        onChange={onGenderChange}
                        disabled={readOnly}
                        checked={
                          (user.profile != null &&
                            (user.profile.gender == -1 ||
                              user.profile.gender == null)) ||
                          false
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="notselected"
                      >
                        Not Selected
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group col-md-6">
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customSwitch2"
                    defaultChecked={user.active === 1 || false}
                    onChange={onActiveChange}
                    disabled={readOnly}
                    name="activeswitch"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customSwitch2"
                  >
                    Active
                  </label>
                </div>
              </div>
            </form>
          ) : (
            <span>Loading...</span>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!readOnly ? (
            <Button
              type="button"
              className="btn bg-primary text-white"
              onClick={updateUser}
            >
              Update
            </Button>
          ) : null}

          <Button className="btn bg-white  text-primary" onClick={onModalClose}>
            {readOnly ? "Close" : "Cancel"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default UserModal;
