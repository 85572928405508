import React from "react";
import {
  generateId,
  generateToast,
  ToastTypes,
} from "components/Toast/ToastHelper";
import { checkAuth } from "utils/auth";
import { Modal, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTable, useSortBy } from "react-table";
import toast from "components/Toast/Toast.css";
import { Card, CardHeader, Table, Container, Row, Spinner } from "reactstrap";
import { useMemo, useState, useEffect, useContext } from "react";
import { AppContext } from "components/Context/ToastContext";
import { getQuestion, postQuestion, putQuestion } from "services/Api";
import { getJSON } from "jquery";

const QuestionModal = (props) => {
  const [question, setQuestion] = useState({ active: true });
  const [options, setOptions] = useState([]);
  const { showToast, setLoader } = useContext(AppContext);
  let redirect = useHistory();

  const onCancelClicked = () => {
    props.close(false);
    clearData();
  };

  const clearData = () => {
    setOptions([]);
    setQuestion({ active: true });
  };

  useEffect(() => {
    if (props.question != null && props.question.id != null) {
      fetchData();
    } else clearData();
  }, [props.question]);

  const fetchData = async () => {
    setLoader(true);
    console.log("QuestionModal fetchData", props.paper);
    getQuestion(props.paper.id, props.question.id)
      .then((res) => {
        setQuestion(res.data.data.question);
        setOptions(res.data.data.question.options);
        console.log(res.data.data);
        setLoader(false);
      })
      .catch((error) => {
        checkAuth(error, redirect);
        console.log(error);
      });
  };

  const onSaveClicked = () => {
    setLoader(true);
    var data = { ...question, options: JSON.stringify(options) };
    console.log("onSaveClicked", props.paper.id, data);
    if (data.id != null && data.id != 0) {
      apiPutQuestion(data);
    } else {
      apiPostQuestion(data);
    }
  };

  const apiPostQuestion = (data) => {
    postQuestion(props.paper.id, data)
      .then((res) => {
        setLoader(false);
        showToast(ToastTypes.success, "Question added Sucessfully");
        props.close(true);
        clearData();
      })
      .catch((error) => {
        checkAuth(error, redirect);
        console.log(error);
        setLoader(false);
        showToast(ToastTypes.error, error.response.data.message);
      });
  };

  const apiPutQuestion = (data) => {
    putQuestion(props.paper.id, data.id, data)
      .then((res) => {
        setLoader(false);
        showToast(ToastTypes.success, "Question updated Sucessfully");
        props.close(true);
        clearData();
      })
      .catch((error) => {
        checkAuth(error, redirect);
        console.log(error);
        setLoader(false);
        showToast(ToastTypes.error, error.response.data.message);
      });
  };

  const addOption = () => {
    var optionsCopy = [...options];
    optionsCopy.push({ is_correct: false });
    setOptions(optionsCopy);
    console.log("addOption", optionsCopy, options);
  };
  const removeOption = (index) => {
    var optionsCopy = [...options];
    console.log("removeOption", index, optionsCopy);
    if (
      confirm(
        "Are you sure you want to delete this option? This action cannot be undone."
      )
    ) {
      var k = optionsCopy.splice(index, 1);
      console.log("removeOption spliced", index, optionsCopy, k);
      setOptions(optionsCopy);
    }
  };

  const onQuestionChange = (e) => {
    setQuestion({ ...question, [e.target.name]: e.target.value });
  };

  const onImageChange = (e) => {
    console.log("onImageChange", e.target.files[0]);
    setQuestion({ ...question, image: e.target.files[0] });
  };

  const onTextChange = (e, index) => {
    var optionsCopy = [...options];
    optionsCopy[index]["text"] = e.target.value;
    console.log("onTextChange", optionsCopy);
    setOptions(optionsCopy);
  };

  const onActiveToggle = (e) => {
    setQuestion({ ...question, [e.target.name]: e.target.checked });
  };

  const onCheckChange = (e, index) => {
    var optionsCopy = [...options];
    optionsCopy[index]["is_correct"] = e.target.checked;
    console.log("onCheckChange", optionsCopy);
    setOptions(optionsCopy);
  };

  return (
    <>
      <Modal
        show={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Card className="shadow " style={{ padding: "10px 20px" }}>
          <CardHeader>
            <div className="row">
              <div className="col-md-12 form-group">
                <label htmlFor="question">Question</label>
                <input
                  type="text"
                  name="question"
                  id="question"
                  className="form-control"
                  value={question.question}
                  onChange={(e) => onQuestionChange(e)}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4">
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="active"
                    checked={question.active}
                    onChange={(e) => onActiveToggle(e)}
                    name="active"
                  />
                  <label className="custom-control-label mt-2" htmlFor="active">
                    Active
                  </label>
                </div>
                <br />
                <small>
                  (If active is OFF, question will not be shown in exam)
                </small>
              </div>
              <div className="form-group col-md-8">
                <label htmlFor="image">Question Image</label>
                <input
                  type="file"
                  className="form-control-file"
                  id="image"
                  aria-describedby="fileHelp"
                  accept="image/*"
                  onChange={(event) => onImageChange(event)}
                />

                <div>
                  {(question.image || question.image_url) && (
                    <div className="col-md-6">
                      <img
                        className=" img-fluid"
                        alt="image"
                        src={
                          question.image
                            ? URL.createObjectURL(question.image)
                            : question.image_url
                        }
                        height="150"
                        width="150"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </CardHeader>
          <table>
            <thead>
              <tr>
                <th>Option</th>
                <th>Correct</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {options.map(function (option, index) {
                return (
                  <tr>
                    <td>
                      <input
                        type="text"
                        placeholder="Option Text"
                        value={option.text}
                        onChange={(e) => onTextChange(e, index)}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={option.is_correct}
                        onChange={(e) => onCheckChange(e, index)}
                      />
                    </td>
                    <td>
                      <button
                        className="btn btn-sm bg-danger rounded-pill ts-btn text-white mr-2"
                        onClick={() => {
                          removeOption(index);
                        }}
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="row  mt-5 align-item-center justify-content-center ">
            <button
              className="btn btn-outline-secondary btn-sm"
              onClick={addOption}
            >
              Add Option
            </button>
          </div>

          <Modal.Footer>
            <button className="btn btn-primary" onClick={() => onSaveClicked()}>
              Save
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => onCancelClicked()}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Card>
      </Modal>
    </>
  );
};

export default QuestionModal;
