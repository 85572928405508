import React, { useMemo, useState, useEffect, useContext } from "react";
import { useTable, useSortBy } from "react-table";
import Batch from "./Batch";
import { getBatches } from "services/Api";
import { checkAuth } from "utils/auth";
import { Card, CardHeader, Table, Container, Row, Spinner } from "reactstrap";
import NewBatchModal from "./NewBatchModal";
import { useHistory } from "react-router-dom";
import { AppContext } from "components/Context/ToastContext";

const BatchList = (props) => {
  const [data, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [batch, setBatch] = useState(null);
  const [loading, setLoading] = useState(false);
  const { setLoader } = useContext(AppContext);
  const redirect = useHistory();
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    setLoader(true);
    getBatches()
      .then((res) => {
        console.log("Batches summary response", res.data.data.batches);
        setTableData(res.data.data.batches);
        setLoader(false);
      })
      .catch((error) => {
        checkAuth(error, redirect);
        console.log(error);
      });
      
  };
  const isOpen = () => {
    setShowModal(true);
  };
  const isClose = () => {
    setShowModal(false);
    getData();
  };
  const columns = useMemo(
    () => [
      {
        Header: "Batch Id",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Status",
        accessor: "active",
        Cell: (s) => (
          <span className={s.value === false ? "text-danger" : "text-success"}>
            {s.value === false ? "Inactive" : "Active"}
          </span>
        ),
      },
      {
        Header: "Actions",
        Cell: (row) => (
          <div>
            <button
              className=" btn btn-sm bg-info text-white"
              onClick={() => {
                redirect.push(`batches/${row.row.original.id}/users`);
              }}
            >
              View
            </button>
            <button className=" btn bg-success btn-sm text-white">Add</button>
          </div>
        ),
      },
    ],
    []
  );
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({ columns, data }, useSortBy);

  return (
    <>
      <Container fluid>
        {loading ? (
          <Spinner />
        ) : (
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <div className="row">
                    <div className="col-12 text-right">
                      <button
                        className="btn bg-primary text-white btn-sm flex-end"
                        onClick={isOpen}
                      >
                        New Batch
                      </button>
                    </div>
                  </div>
                </CardHeader>

                <Table columns={columns} data={data} />
                <table {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            className={
                              column.isSorted
                                ? column.isSortedDesc
                                  ? "sort-desc"
                                  : "sort-asc"
                                : ""
                            }
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>

                  <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Card>
            </div>
          </Row>
        )}
      </Container>
      {batch ? <Batch batch={batch} close={() => setBatch(null)} /> : null}
      <NewBatchModal show={showModal} close={isClose} />
    </>
  );
};

export default BatchList;
