import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { toast } from "components/Toast/Toast.css";

const Toast = (props) => {
  const { toastList, position, autoDeleteTime } = props;
  const [list, setList] = useState(toastList);

  useEffect(() => {
    setList(toastList);
  }, [toastList, list]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (toastList.length && list.length) {
        deleteToast(toastList[0].id);
      }
    }, autoDeleteTime);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [toastList, autoDeleteTime, list]);

  const deleteToast = (id) => {
    const index = list.findIndex((e) => e.id === id);
    list.splice(index, 1);
    setList([...list]);
  };

  return (
    <div className={`notification-container ${position}`}>
      {list.map((toast, i) => (
        <div
          key={i}
          className={`notification toast ${position}`}
          style={{ backgroundColor: toast && toast.backgroundColor }}
        >
          <button onClick={() => deleteToast(toast.id)}>X</button>
          <div className="notification-image">
            <img src={toast && toast.icon} alt="toast-icon" />
          </div>
          <div>
            <p className="notification-title">{toast && toast.title}</p>
            <p className="notification-message">{toast && toast.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

Toast.propTypes = {
  toastList: PropTypes.array.isRequired,
  position: PropTypes.string.isRequired,
  // autoDelete: PropTypes.bool,
  autoDeleteTime: PropTypes.number,
};
export default Toast;
