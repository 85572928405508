import React, { useMemo, useState, useEffect, useContext } from "react";
import { useTable, useSortBy } from "react-table";
import axios from "axios";
import "components/css/Table.css";
import Moment from "moment";
import { getNotifications } from "services/Api";
import { Modal, Button } from "react-bootstrap";
import { NotificationModal } from "pages/notifications/NotificationModal";
import { Card, CardHeader, Table, Container, Row, Spinner } from "reactstrap";
import { checkAuth } from "utils/auth";
import { useHistory } from "react-router-dom";
import { AppContext } from "components/Context/ToastContext";
const Notifications = () => {
  const [data, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setLoader } = useContext(AppContext);
  const redirect = useHistory();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoader(true);
    getNotifications()
      .then((res) => {
        console.log(
          "Notificaton summary response",
          res.data.data.notifications
        );
        setTableData(res.data.data.notifications);
        setLoader(false);
      })
      .catch((error) => {
        checkAuth(error, redirect);
        console.log(error);
      });
  };

  const isOpen = () => {
    setShowModal(true);
  };
  const isClose = () => {
    setShowModal(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Sent To",
        accessor: "category",
      },
      {
        Header: "Sent At",
        accessor: (d) => {
          return Moment(d.created_at).local().format("DD-MM-YYYY hh:mm a");
        },
      },
    ],
    []
  );
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({ columns, data }, useSortBy);

  return (
    <>
      {/* <Header /> */}
      {/* Page content */}
      <Container fluid>
        {loading ? (
          <Spinner />
        ) : (
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <div className="row">
                    <div className="col text-right">
                      <button
                        className="btn bg-primary text-white btn-sm "
                        onClick={isOpen}
                      >
                        Send Notifications
                      </button>
                    </div>
                  </div>
                </CardHeader>

                <Table columns={columns} data={data} />
                <table {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            className={
                              column.isSorted
                                ? column.isSortedDesc
                                  ? "sort-desc"
                                  : "sort-asc"
                                : ""
                            }
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>

                  <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Card>
            </div>
          </Row>
        )}
      </Container>
      <NotificationModal show={showModal} close={isClose} />
    </>
  );
};

export default Notifications;
