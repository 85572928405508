import React, { useContext, useMemo, useState } from "react";
import { useSortBy, useTable } from "react-table";
import { useHistory } from "react-router-dom";
import { Card, Table, Container, Row, Spinner, CardHeader } from "reactstrap";
import { AppContext } from "components/Context/ToastContext";
import { useEffect } from "react";
import { checkAuth } from "utils/auth";
import { getScholarship } from "services/Api";


const ScholarshipProfiles = () => {
  const redirect = useHistory();
  const [data, setTableData] = useState([]);
  
  const { setLoader } = useContext(AppContext);
  useEffect(() => {
    getScholarshiplist();
  }, []);
  const getScholarshiplist = () => {
    setLoader(true);
    getScholarship()
      .then((res) => {
        console.log("ScholarshipList", res.data.data.scholarships);
        setTableData(res.data.data.scholarships);
        setLoader(false);
      })
      .catch((error) => {
        checkAuth(error, redirect);
        console.log("ScholarshipList Error:", error);
      });
  };
  const columns = useMemo(
    () => [
      {
        Header: "App Id",
        accessor: "user_id",
      },
      {
        Header: "Full Name",
        accessor: "name",
      },
      {
        Header: "Category",
        accessor: "category",
      },
      {
        Header: "Annual Family Income",
        accessor: "annualincome",
      },
      {
        Header: "State",
        accessor: "state",
      },
      {
        Header: "Course Level",
        accessor: "course_level",
      },
      {
        Header: "Course Name",
        accessor: "course_name",
      },
      {
        Header: "%",
        accessor: "percentage",
      },
      {
        Header: "ACTIONS",
        Cell: (row) => (
          <div className="table-btn">
            <button
              className="btn btn-sm bg-success rounded-pill ts-btn text-white   mr-2"
              onClick={() => {
                redirect.push(`scholarship_details/${row.row.original.id}`);
                console.log(row.row.original.id);
              }}
            >
              View
            </button>

          
          </div>
        ),
      },
    ],
    []
  );

  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({ columns, data }, useSortBy);

  return (
    <>
      <Container fluid>
        <Row></Row>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0"></CardHeader>
              <Table
                className="col offset-lg-1"
                columns={columns}
                data={data}
              />
              <div className="table-responsive">
                <table
                  className="table table-sm"
                  {...getTableProps()}
                  style={{ overflowY: "hidden" }}
                >
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            className={
                              column.isSorted
                                ? column.isSortedDesc
                                  ? "sort-desc"
                                  : "sort-asc"
                                : ""
                            }
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>

                  <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ScholarshipProfiles;
