import React, { useState, useEffect, useRef, useContext } from "react";
import Index from "pages/Index";
import { Modal, Button } from "react-bootstrap";
import { getCourses } from "services/Api";
import { postGrantAccess } from "services/Api";
import { checkAuth, handleApiError } from "utils/auth";
import { useHistory } from "react-router-dom";
import { AppContext } from "components/Context/ToastContext";
import { ToastTypes } from "components/Toast/ToastHelper";

const GrantAccessModal = (props) => {
  // these are for fetching
  const [courses, setCourses] = useState([]);
  const [combos, setCombos] = useState([]);
  const [loading, setLoading] = useState(false);
  const { showToast, setLoader } = useContext(AppContext);
  const redirect = useHistory();
  // this is for sending
  const [grantAccess, setGrantAccess] = useState({
    entity_type: "",
    entity_id: 0,
    description: "",
  });

  const coursesApi = async () => {
    setLoader(true);
    getCourses()
      .then((res) => {
        console.log("Grant access Modal Courses ", res.data.data);
        setCourses(res.data.data.courses);
        setCombos(res.data.data.combos);
        setLoader(false);
      })
      .catch((error) => {
        checkAuth(error, redirect);
        console.log(error);
      });
  };
  const onModalClose = () => {
    setGrantAccess({
      entity_type: "",
      entity_id: "",
      description: "",
    });
    props.close();
  };
  useEffect(() => {
    if (props.user != null) coursesApi();
  }, [props.user]);
  const postGrantAccessData = () => {
    setLoader(true);
    postGrantAccess(props.user.id, grantAccess)
      .then((res) => {
        console.log("Grant Access Modal Post Data", res.data);
        showToast(ToastTypes.success, "Course added Sucessfully");
        setLoader(false);
        onModalClose();
      })
      .catch((error) => {
        checkAuth(error, redirect);
        setLoader(false);
        showToast(ToastTypes.error, error.response.data.message);
        console.log(error);
      });
  };
  const onChange = (e) => {
    setGrantAccess({ ...grantAccess, [e.target.name]: e.target.value });
    console.log(
      "Grant User Changed",
      grantAccess,
      e.target.value,
      e.target.name
    );
  };
  const onIdChange = (e, type, id) => {
    e.persist();
    grantAccess.entity_type = type; // 1 : combo , 0 : courses
    grantAccess.entity_id = id; // 1 : student , 0 : others
    setGrantAccess({ ...grantAccess });
    console.log("id changed ", type, id, grantAccess);
  };
  return (
    <>
      {grantAccess ? (
        <Modal
          show={props.user != null}
          onHide={props.close}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className=" ">
            <Modal.Title id="contained-modal-title-vcenter d-flex">
              <div className="form-row ">
                <div className="form-group col-md- justify-content-start">
                  <label
                    htmlFor="inputName my-2"
                    style={{ fontWeight: "bold" }}
                  >
                    Name:
                  </label>
                  <label htmlFor="userName">{props.user.name}</label>
                </div>
                <div className="form-group col-md justify-content-end">
                  <label htmlFor="inputEmail4" style={{ fontWeight: "bold" }}>
                    Id:
                  </label>
                  <label htmlFor="userId">{props.user.id}</label>
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {courses.length > 0 ? (
              <form>
                <div className="form-row">
                  <div className="form-group col-md-5">
                    <label
                      htmlFor="course"
                      className="fs-20"
                      style={{ fontWeight: "bold" }}
                    >
                      Courses
                    </label>
                    {courses.map((course) => {
                      let htmlId = "0_" + course.id;
                      return (
                        <>
                          <div key={htmlId} className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="entity_id"
                              id={htmlId}
                              defaultChecked={
                                (grantAccess.entity_type === 0 &&
                                  grantAccess.entity_id === 0) ||
                                false
                              }
                              onChange={(e) => onIdChange(e, 0, course.id)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={htmlId}
                            >
                              {course.name}
                            </label>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-5">
                    <label htmlFor="course" style={{ fontWeight: "bold" }}>
                      Combo
                    </label>
                    {combos.map((combo, idx) => {
                      let htmlId = "1_" + combo.id;
                      return (
                        <>
                          <div key={htmlId} className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="entity_id"
                              defaultChecked={
                                (grantAccess.entity_type === 1 &&
                                  grantAccess.entity_id === 0) ||
                                false
                              }
                              onChange={(e) => onIdChange(e, 1, combo.id)}
                              id={htmlId}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={htmlId}
                            >
                              {combo.name}
                            </label>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-10">
                    <label htmlFor="inputName" style={{ fontWeight: "bold" }}>
                      Notes
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="note"
                      name="description"
                      value={grantAccess.description || ""}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </form>
            ) : (
              <span>Loading....</span>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={postGrantAccessData}>Grant Access </Button>
            <Button onClick={onModalClose}>Cancel</Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
};

export default GrantAccessModal;
