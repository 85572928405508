import React from "react";
import { Card, Table, Container, Row, Spinner, CardHeader } from "reactstrap";
import { AppContext } from "components/Context/ToastContext";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { getScholarshipDetails } from "services/Api";
import { useParams } from "react-router-dom";
import { saveAs } from "file-saver";

const ScholarshipDetails = () => {
  const [scholarshipDetails, setScholarshipDetails] = useState({});
  // const [isHover, setIsHover] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setLoader } = useContext(AppContext);
  let { id } = useParams();
  const getData = async () => {
    setLoader(true);
    getScholarshipDetails(id)
      .then((res) => {
        console.log("scholarship list ", res.data.data.scholarship_profile);
        setScholarshipDetails(res.data.data.scholarship_profile);
        setLoader(false);
      })
      .catch((error) => {
        checkAuth(error, redirect);
        console.log(error);
      });
  };
  const saveFile = (filePath) => {
    saveAs(filePath, "example.pdf");
  };
  // const handleMouseEnter = () => {
  //   setIsHover(true);
  // };
  // const handleMouseLeave = () => {
  //   setIsHover(false);
  // };
  // const buttonStyle = {
  //   color: isHover ? "white" : "",
  // };
  useEffect(() => {
    if (id != null) getData();
  }, []);
  return (
    <>
      {/* <Header /> */}
      {/* Page content */}
      <Container>
        {loading ? (
          <Spinner />
        ) : (
          // <Row>
          <Card className="shadow">
            <div className="card-body align-item-center">
              <form>
                <div className="form-row">
                  <div className="col-md-1 form-group">
                    <label>Id</label>
                    <input
                      type="text"
                      className="form-control"
                      name="id"
                      defaultValue={scholarshipDetails.id}
                      placeholder=""
                      readOnly
                    />
                  </div>
                  <div className="col form-group">
                    <label>Full Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="fullname"
                      defaultValue={scholarshipDetails.name}
                      placeholder=" "
                      readOnly
                    />
                  </div>

                  <div className="col form-group">
                    <label>Address Locality</label>
                    <input
                      type="text"
                      className="form-control"
                      name="locality"
                      defaultValue={scholarshipDetails.address_locality}
                      placeholder=" "
                      readOnly
                    />
                  </div>
                  <div className="col form-group">
                    <label>Address Pin</label>
                    <input
                      type="text"
                      className="form-control"
                      name="addresspin"
                      defaultValue={scholarshipDetails.address_pin}
                      placeholder=" "
                      readOnly
                    />
                  </div>
                  <div className="col form-group">
                    <label>State</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      name="state"
                      defaultValue={scholarshipDetails.address_state}
                      readOnly
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="col form-group ">
                    <label>Address Proof</label>
                    { scholarshipDetails.address_pin && (
                    <input
                      type="button"
                      className="btn btn-sm bg-primary rounded-pill ts-btn text-white "
                      name="address Proof"
                      onClick={() => saveFile(scholarshipDetails.address_proof)}
                      value="Download"
                      placeholder=""
                      readOnly
                    />)}
                  </div>
                  <div className="col form-group">
                    <label>Address Taluka</label>
                    <input
                      type="text"
                      className="form-control"
                      name="taluka"
                      defaultValue={scholarshipDetails.address_taluka}
                      placeholder=" "
                      readOnly
                    />
                  </div>
                  <div className="col form-group">
                    <label>Admission Letter</label>
                    {scholarshipDetails.admission_letter && (
                    <input
                      type="button"
                      className="btn btn-sm bg-primary rounded-pill ts-btn text-white"
                      name="Address Letter"
                      onClick={() => saveFile(scholarshipDetails.admission_letter)}
                      value="Download"
                      placeholder=""
                      readOnly
                    />)}
                  </div>

                  <div className="col form-group">
                    <label>Annual Income</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      name="annualincome"
                      defaultValue={scholarshipDetails.annual_income}
                      readOnly
                    />
                  </div>
                  <div className="col form-group">
                    <label>Bank Acc.No.</label>
                    <input
                      type="text"
                      className="form-control"
                      name="bank_acc_no"
                      defaultValue={scholarshipDetails.bank_acc_no}
                      placeholder=" "
                      readOnly
                    />
                  </div>
                  <div className="col form-group">
                    <label>Branch Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="branchname"
                      defaultValue={scholarshipDetails.bank_branch_name}
                      placeholder=" "
                      readOnly
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col form-group">
                    <label>Holder Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="holdername"
                      defaultValue={scholarshipDetails.bank_holder_name}
                      placeholder=" "
                      readOnly
                    />
                  </div>
                  <div className="col form-group">
                    <label>IFSC</label>
                    <input
                      type="text"
                      className="form-control"
                      name="ifsc"
                      defaultValue={scholarshipDetails.bank_ifsc}
                      placeholder=" "
                      readOnly
                    />
                  </div>
                  <div className="col form-group">
                    <label>Bank Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      defaultValue={scholarshipDetails.bank_name}
                      placeholder=" "
                      readOnly
                    />
                  </div>
                  <div className="col form-group">
                    <label>Bank Proof</label>
                    {scholarshipDetails.bank_proof && (
                    <input
                      type="button"
                      className="btn btn-sm bg-primary rounded-pill ts-btn text-white"
                      name="Tuition Fees Proof"
                      onClick={() => saveFile(scholarshipDetails.bank_proof)}
                      value="Download"
                      placeholder=""
                      readOnly
                    />)}
                  </div>
                  <div className="col form-group">
                    <label>Category</label>
                    <input
                      type="text"
                      className="form-control"
                      name="category"
                      defaultValue={scholarshipDetails.category}
                      placeholder=""
                      readOnly
                    />
                  </div>
                  <div className="col form-group">
                    <label>CGPA</label>
                    <input
                      type="text"
                      className="form-control"
                      name="category"
                      defaultValue={scholarshipDetails.cgpa_obtained}
                      placeholder=""
                      readOnly
                    />
                  </div>

                  <div className="col form-group">
                    <label>CGPA Outof</label>
                    <input
                      type="text"
                      className="form-control"
                      name="category"
                      defaultValue={scholarshipDetails.cgpa_out_of}
                      placeholder=""
                      readOnly
                    />
                  </div>
                  <div className="col form-group">
                    <label>Gender</label>
                    <input
                      type="text"
                      className="form-control"
                      name="completedyears"
                      defaultValue={scholarshipDetails.gender}
                      placeholder=""
                      readOnly
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col form-group">
                    <label>Course Level</label>
                    <input
                      type="text"
                      className="form-control"
                      name="courselevel"
                      defaultValue={scholarshipDetails.course_level}
                      placeholder=""
                      readOnly
                    />
                  </div>
                  <div className="col form-group">
                    <label>Course Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="coursename"
                      defaultValue={scholarshipDetails.course_name}
                      placeholder=""
                      readOnly
                    />
                  </div>
                  <div className="col form-group">
                    <label>Created At</label>
                    <input
                      type="text"
                      className="form-control"
                      name="created at"
                      defaultValue={scholarshipDetails.created_at}
                      placeholder=""
                      readOnly
                    />
                  </div>
                  <div className="col form-group">
                    <label>Fees</label>
                    <input
                      type="text"
                      className="form-control"
                      name="fees"
                      defaultValue={scholarshipDetails.current_fees}
                      placeholder=""
                      readOnly
                    />
                  </div>
                  <div className="col form-group">
                    <label>DOB</label>
                    <input
                      type="text"
                      className="form-control"
                      name="dob"
                      defaultValue={scholarshipDetails.dob}
                      placeholder=""
                      readOnly
                    />
                  </div>
                  <div className="col form-group">
                    <label>Email</label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      defaultValue={scholarshipDetails.email}
                      placeholder=""
                      readOnly
                    />
                  </div>

                  <div className="col form-group">
                    <label>Identity Proof</label>
                    { scholarshipDetails.identity_Proof && (
                    <input
                      type="button"
                      className="btn btn-sm bg-primary rounded-pill ts-btn text-white"
                      name="Tuition Fees Proof"
                      onClick={() => saveFile(scholarshipDetails.identity_proof)}    
                      value="Download"
                      placeholder=""
                      readOnly
                    />)}
                  </div>
                </div>
                <div className="form-row">
                  <div className="col form-group">
                    <label>Income Proof</label>
                    { scholarshipDetails.income_proof && (
                    <input
                      type="button"
                      className="btn btn-sm bg-primary rounded-pill ts-btn text-white"
                      name="Tuition Fees Proof"
                      onClick={() => saveFile(scholarshipDetails.income_proof)}
                      value="Download"
                      placeholder=""
                      readOnly
                    />)}
                  </div>
                  <div className="col form-group">
                    <label>Institute name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="institute name"
                      defaultValue={scholarshipDetails.institute_name}
                      placeholder=""
                      readOnly
                    />
                  </div>
                  <div className="col form-group">
                    <label>Institute State</label>
                    <input
                      type="text"
                      className="form-control"
                      name="institute state"
                      defaultValue={scholarshipDetails.institute_state}
                      placeholder=""
                      readOnly
                    />
                  </div>
                  <div className="col form-group">
                    <label>Marks Obtained</label>
                    <input
                      type="text"
                      className="form-control"
                      name="marks obtained"
                      defaultValue={scholarshipDetails.marks_obtained}
                      placeholder=""
                      readOnly
                    />
                  </div>
                  <div className="col form-group">
                    <label>Marks out of</label>
                    <input
                      type="text"
                      className="form-control"
                      name="marks out of"
                      defaultValue={scholarshipDetails.marks_out_of}
                      placeholder=""
                      readOnly
                    />
                  </div>
                  <div className="col form-group">
                    <label>Marksheet</label>
                    { scholarshipDetails.marksheet && (
                    <input
                      type="button"
                      className="btn btn-sm bg-primary rounded-pill ts-btn text-white"
                      name="Tuition Fees Proof"
                      onClick={() => saveFile(scholarshipDetails.marksheet)}
                      value="Download"
                      placeholder=""
                      readOnly
                    />)}
                  </div>
                  <div className="col form-group">

                    <label>Mobile No</label>
                    <input
                      type="text"
                      className="form-control"
                      name="mobile no"
                      defaultValue={scholarshipDetails.mobile_no}
                      placeholder=""
                      readOnly
                    />
                  </div>
                  <div className="col form-group">
                    <label>NonTuition Fees</label>
                    <input
                      type="text"
                      className="form-control"
                      name="non tutuion fees"
                      defaultValue={scholarshipDetails.non_tuition_fees}
                      placeholder=""
                      readOnly
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col form-group">
                    <label>Non Tuition Proof</label>
                    { scholarshipDetails.non_tuition_fees_proof &&(
                    <input
                      type="button"
                      className="btn btn-sm bg-primary rounded-pill ts-btn text-white"
                      name="NontuitionProof"
                      onClick={() => saveFile(scholarshipDetails.non_tuition_fees_proof)}
                      value="Download"
                      placeholder=""
                      readOnly
                    />)}
                  </div>
                  <div className="col form-group">
                    <label>Other Certificate</label>
                   { scholarshipDetails.other_scholarship &&(
                    <input
                      type="text"
                      className="form-control"
                      name="Other Certificate"
                      onClick={() => saveFile(scholarshipDetails.other_scholarship)}
                      placeholder=""
                      readOnly
                    />
                   )}
                  </div>
                  <div className="col form-group">
                    <label>Other Scholarship</label>
                    <input
                      type="text"
                      className="form-control"
                      name="other scholarship"
                      defaultValue={scholarshipDetails.other_scholarship}
                      placeholder=""
                      readOnly
                    />
                  </div>
                  <div className="col form-group">
                    <label>Other Scholarship Amount</label>
                    <input
                      type="text"
                      className="form-control"
                      name="other scholarship amount"
                      defaultValue={scholarshipDetails.other_scholarship_amount}
                      placeholder=""
                      readOnly
                    />
                  </div>
                  <div className="col form-group">
                    <label>Other Scholarship Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="other scholarship name"
                      defaultValue={scholarshipDetails.other_scholarship_name}
                      placeholder=""
                      readOnly
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col form-group">
                    <label>Parent Aadhar No</label>
                    <input
                      type="text"
                      className="form-control"
                      name="parent aadhar no"
                      defaultValue={scholarshipDetails.parent_aadhar_no}
                      placeholder=""
                      readOnly
                    />
                  </div>
                  <div className="col form-group">
                    <label>Parent Mobile No</label>
                    <input
                      type="text"
                      className="form-control"
                      name="parent mobile no"
                      defaultValue={scholarshipDetails.parent_mobile_no}
                      placeholder=""
                      readOnly
                    />
                  </div>
                  <div className="col form-group">
                    <label>Percentage</label>
                    <input
                      type="text"
                      className="form-control"
                      name="percentage"
                      defaultValue={scholarshipDetails.percentage}
                      placeholder=""
                      readOnly
                    />
                  </div>
                  <div className="col form-group">
                    <label>Percentage Type</label>
                    <input
                      type="text"
                      className="form-control"
                      name="percentage type"
                      defaultValue={scholarshipDetails.percentage_type}
                      placeholder=""
                      readOnly
                    />
                  </div>
                  <div className="col form-group">
                    <label>Photo</label>
                    <br />
                    { scholarshipDetails.photo && (
                    <input
                      type="button"
                      className="btn btn-sm bg-primary rounded-pill ts-btn text-white"
                      name="photo"
                      onClick={() => saveFile(scholarshipDetails.photo)}
                      value="Download"
                      placeholder=""
                      readOnly
                    />)}
                  </div>
                </div>
                <div className="form-row">
                  <div className="col form-group">
                    <label>Secured Admission</label>
                    <input
                      type="text"
                      className="form-control"
                      name="secured admission"
                      defaultValue={scholarshipDetails.secured_admission}
                      placeholder=""
                      readOnly
                    />
                  </div>
                  <div className="col form-group">
                    <label>Tuition Fees</label>
                    <input
                      type="text"
                      className="form-control"
                      name="tuition fees"
                      defaultValue={scholarshipDetails.tuition_fees}
                      placeholder=""
                      readOnly
                    />
                  </div>
                  <div className="col form-group">
                    <label>Tuition Fees Proof</label>
                    { scholarshipDetails.tuition_fees_proof && (
                    <input
                      type="button"
                      className="btn btn-sm bg-primary rounded-pill ts-btn text-white"
                      name="Tuition Fees Proof"
                      onClick={() => saveFile(scholarshipDetails.tuition_fees_proof)}
                      value="Download"
                      placeholder=""
                      readOnly
                    />)}
                  </div>
                  <div className="col form-group">
                    <label>Updated At</label>
                    <input
                      type="text"
                      className="form-control"
                      name="updated at"
                      defaultValue={scholarshipDetails.updated_at}
                      placeholder=""
                      readOnly
                    />
                  </div>
                  <div className="col form-group">
                    <label>User Id</label>
                    <input
                      type="text"
                      className="form-control"
                      name="user id"
                      defaultValue={scholarshipDetails.user_id}
                      placeholder=""
                      readOnly
                    />
                  </div>
                </div>
              </form>
            </div>
          </Card>
        )}
      </Container>
    </>
  );
};

export default ScholarshipDetails;
