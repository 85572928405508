import React from "react";

const Loader = (props) => {
  const myStyle = {
    position: "fixed",
    width: "100%",
    height: "100%",
    backgroundColor: "#55555555",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "9999",
  };
  return (
    <>
      {props.show ? (
        <div id="spinner" style={myStyle}>
          <i
            className="fa fa-spinner fa-pulse fa-3x fa-fw"
            style={{ fontSize: 36, color: "#2F455C" }}
          />
        </div>
      ) : null}
    </>
  );
};

export default Loader;
