import { Card, CardHeader, Container, Table } from "reactstrap";
import "components/css/Table.css";
import React, { useEffect, useMemo, useState, useRef, useContext } from "react";
import { useSortBy, useTable } from "react-table";
import { getUsers } from "services/Api";
import { useHistory } from "react-router-dom";
import performLogout, { checkAuth, getLoggedUser } from "utils/auth";
import excel from "utils/excel";
import Userlist from "components/css/UserList.css";
import { AppContext } from "components/Context/ToastContext";
import GrantAccessModal from "./GrantAccessModal";
import UserModal from "./UserModal";
import Roles from "services/Roles";

const UserList = (props) => {
  const [data, setTableData] = useState([]);
  const [paidCount, setPaidCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [user, setUser] = useState(null);
  const [grantUser, setGrantUser] = useState(null);
  const { setLoader } = useContext(AppContext);

  const redirect = useHistory();
  const txtRef = useRef(null);
  const selectRef = useRef(0);
  const loggedUser = getLoggedUser();
  const dataMemo = useMemo(() => data, [data]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (text = null, type = 0) => {
    setLoader(true);
    getUsers({ text, type })
      .then((res) => {
        console.log("Dashboard Summary Response", res.data.data);
        setTableData(res.data.data.users);
        setPaidCount(res.data.data.paid_count);
        setTotalCount(res.data.data.total_count);
        setLoader(false);
      })
      .catch((error) => {
        checkAuth(error, redirect);
        console.log("getUsers error:", error);
      });
  };

  // when clicked enter on search input text field
  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      onSearchClick();
    }
  };

  const onExcelClick = () => {
    excel.exportToExcel(data);
  };

  const onGrantAccessModalClose = () => {
    setGrantUser(null);
    onSearchClick();
  };

  const onSearchClick = () => {
    const type = selectRef.current.value;
    const text = txtRef.current.value;
    console.log("onSearchClick", type, text);
    fetchData(text, type);
  };

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "NAME",
        accessor: "name",
      },
      {
        Header: "EMAIl",
        accessor: "email",
      },
      {
        Header: "CONTACT",
        accessor: "contact_no",
      },
      // {
      //   Header: "INSTITUTE",
      //   accessor: "institute",

      // },
      // {
      //   Header: "UNIVERSITY",
      //   accessor: "university",
      //   style: {
      //     maxWidth: "250px",
      //     wordWrap: "break-all",
      //     overflow: "hidden",
      //   },
      // },
      {
        Header: "REGISTRED ON",
        accessor: "registered_on",
      },
      {
        Header: "PURCHASED",
        Cell: (row) => (
          <div className="table-btn">
            {row.row.original.purchased
              ? row.row.original.purchased.split(",").map((item, index) => (
                  <div key={index}>
                    {item}
                    <br />
                  </div>
                ))
              : null}
          </div>
        ),
      },
      {
        Header: "ACTIONS",
        Cell: (row) => (
          <div className="table-btn">
            <button
              className="btn btn-sm bg-success rounded-pill ts-btn text-white   mr-2"
              onClick={() => setUser(row.row.original)}
            >
              View
            </button>
            {loggedUser && loggedUser.role == Roles.ADMIN ? (
              <button
                className="btn bg-primary text-white rounded-pill btn-sm"
                onClick={() => setGrantUser(row.row.original)}
              >
                Grant Access
              </button>
            ) : null}
          </div>
        ),
      },
    ],
    []
  );

  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({ columns, data }, useSortBy);

  const onRefreshClick = () => {
    fetchData();
  };
  return (
    <>
      <Container fluid>
        <Card>
          <CardHeader className="border-0">
            <div className="row text-left">
              <div className="col-md-4 col-xs-2">
                <h3 className="mb-0">Registered Users</h3>
              </div>

              <div className="col-md-4  col-xs-12 text-center mb-2">
                <span className="mb-0 mr-3">Total: {totalCount}</span>
                <span className="mb-0 mr-3">Paid : {paidCount}</span>
              </div>

              <div className="col-md-4 col-xs-12 text-right card-btn">
                <button
                  className="btn bg-success text-white btn-sm  mr-2"
                  onClick={onExcelClick}
                >
                  Export Excel
                </button>

                <a
                  href=""
                  className="btn btn-sm bg-default text-white"
                  onClick={onRefreshClick}
                >
                  Refresh List
                </a>
              </div>
            </div>

            <div className="col-md-10 search-form">
              <input
                className="col-md-7"
                type="text"
                ref={txtRef}
                onKeyDown={onKeyDown}
                placeholder="Enter student id, name, email or mobile number"
              />
              <select
                className="col-md-3"
                data-show-content="true"
                ref={selectRef}
              >
                <option value={0}>All Students</option>
                <option value={1}>Paid Only</option>
              </select>

              <input
                type="button"
                onClick={onSearchClick}
                className="col-md-2 bg-blue"
                value="Search"
              />
            </div>
          </CardHeader>

          <Table className="col offset-lg-2" columns={columns} data={data} />
          <div className="table-responsive ">
            <table className="table table-sm" {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className={
                          column.isSorted
                            ? column.isSortedDesc
                              ? "sort-desc"
                              : "sort-asc"
                            : ""
                        }
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            className="text-sm"
                            {...cell.getCellProps()}
                            style={cell.column.style}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Card>
      </Container>
      {user ? <UserModal user={user} close={() => setUser(null)} /> : null}
      {grantUser ? (
        <GrantAccessModal user={grantUser} close={onGrantAccessModalClose} />
      ) : null}
    </>
  );
};

export default UserList;
