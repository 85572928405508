import React from "react";
import { useMemo, useState, useEffect, useContext } from "react";
import { useTable, useSortBy } from "react-table";
import { AppContext } from "components/Context/ToastContext";
import { Card, CardHeader, Table, Container, Row, Spinner } from "reactstrap";
import { checkAuth } from "utils/auth";
import { useHistory } from "react-router-dom";
import { getCourses } from "services/Api";
const CourseList = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setLoader } = useContext(AppContext);
  const redirect = useHistory();
  const routeChange = () => {
    redirect.push(`/admin/courses/new`);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoader(true);
    getCourses()
      .then((res) => {
        setCourses(res.data.data.courses);
        console.log("Course List ", res.data.data);
        setLoader(false);
      })
      
      .catch((error) => {
        checkAuth(error, redirect);
        console.log(error);
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Active",
        accessor: (d) => {
          return d.active == 1 ? "Yes" : "No";
        },
      },
      {
        Header: "Thumbnail",

        Cell: (row) => (
          row.row.original.thumbnail ?
          (<div>
            <img
              className="img-fluid"
              src={row.row.original.thumbnail}
              width={50}
              height={50}
              alt="course-images"
            />
          </div>): null
        ),
      },
      {
        Header: "Price",
        accessor: "default_price",
      },
      {
        Header: "Actions",  
        Cell: (row) => (
          <div className="table-btn">
            <button
              className="btn btn-sm bg-success rounded-pill ts-btn text-white   mr-2"
              onClick={() => {
                redirect.push(`/admin/courses/${row.row.original.id}/edit`);
                console.log(row);
              }}
            >
              Edit
            </button>
            <button
              className="btn btn-sm bg-success rounded-pill ts-btn text-white   mr-2"
              onClick={() => {
                redirect.push(`/admin/courses/${row.row.original.id}/video`);
                console.log(row);
              }}
            >
              Videos
            </button>
          </div>
          
        ),
      },
    
    ],
    []
  );
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({ columns, data: courses }, useSortBy);
  return (
    <>
      {/* <Header /> */}
      {/* Page content */}
      <Container fluid>
        {loading ? (
          <Spinner />
        ) : (
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <div className="row text-left">
                    <div className="col-md-4 col-xs-2">
                      <h3 className="mb-0">New Courses</h3>
                    </div>
                    <div className="col text-right">
                      <button
                        className="btn bg-primary text-white btn-sm "
                        onClick={routeChange}
                      >
                        New Course
                      </button>
                    </div>
                  </div>
                </CardHeader>

                <Table columns={columns} data={courses} />
                <table {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            className={
                              column.isSorted
                                ? column.isSortedDesc
                                  ? "sort-desc"
                                  : "sort-asc"
                                : ""
                            }
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>

                  <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Card>
            </div>
          </Row>
        )}
      </Container>
    </>
  );
};

export default CourseList;
