import { useContext, useEffect, useState } from "react";
import { Link, NavLink as NavLinkRRD } from "react-router-dom";
// nodejs library to set properties for components
import brand_logo from "assets/img/brand/ts_icon.png";
import { PropTypes } from "prop-types";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

import LogoImage from "./../../assets/img/brand/icon_long.png";
import "components/css/Sidebar.css";
// reactstrap components
import {
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppContext } from "components/Context/ToastContext";

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  const [xPosition, setX] = useState(0);

  const toggleMenu = () => {
    if (xPosition < 0) {
      setX(0);
    } else {
      setX(-props.width);
    }
  };

  useEffect(() => {
    setX(0);
  }, []);
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      return prop.display ? (
        <NavLink
          to={prop.layout + prop.path}
          tag={NavLinkRRD}
          className="sidebar-position"
          onClick={closeCollapse}
          activeClassName="active"
          key={key}
        >
          <i className={prop.icon} />
          <span>{prop.name}</span>
        </NavLink>
      ) : null;
    });
  };

  const { sidebarOpen, setSidebarOpen } = useContext(AppContext);

  const handleTrigger = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className={`sidebar ${sidebarOpen ? "sidebar--open" : ""}`}>
      <div className="trigger" onClick={handleTrigger}>
        {sidebarOpen ? (
          <>
            <img
              src={LogoImage}
              style={{
                width: "140px",
                marginRight: "auto",
              }}
            />
            <i className="fa fa-xs fa-times" />
          </>
        ) : (
          <i className="fa fa-sm fa-bars" />
        )}
      </div>
      {createLinks(props.routes)}
    </div>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
