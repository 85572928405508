import React from "react";
import { useMemo, useState, useEffect, useContext } from "react";
import { useTable, useSortBy } from "react-table";
import { AppContext } from "components/Context/ToastContext";
import { Card, CardHeader, Table, Container, Row, Spinner } from "reactstrap";
import { checkAuth } from "utils/auth";
import { useHistory, useParams } from "react-router-dom";
import { getStudents, postUserId } from "services/Api";
import {
  generateId,
  generateToast,
  ToastTypes,
} from "components/Toast/ToastHelper";

const ExamSetUserList = () => {
  const [examUserList, setExamUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [otherUserList, setOtherUserList] = useState(null);
  var selectedUserIndex = -1;
  const { setLoader, showToast } = useContext(AppContext);
  const redirect = useHistory();

  let { id } = useParams();
  const getData = async () => {
    console.log("view students list");
    console.log("id", id);
    setLoader(true);
    getStudents(id)
      .then((res) => {
        setOtherUserList(res.data.data.other_users);
        setExamUserList(res.data.data.exam_set_users);
        console.log("getStudents", res.data.data);
        setLoader(false);
      })
      .catch((error) => {
        checkAuth(error, redirect);
        console.log(error);
      });
  };
  useEffect(() => {
    if (otherUserList == null) getData(otherUserList);
  }, []);

  $(document).ready(function () {
    $(".js-example-basic-single")
      .select2()
      .on("change", function (e) {
        if (selectedUserIndex != e.target.value)
          selectedUserIndex = e.target.value;
      });
  });

  const getPostArray = () => {
    var examUserListCopy = [...examUserList];
    var userIdList = [];
    examUserListCopy.forEach((user) => {
      userIdList.push(user.id);
    });
    return userIdList;
  };

  // index of examUserList
  const removeUser = (e, index) => {
    console.log("removeUser examUserList", examUserList);
    // return;
    var examUserListCopy = [...examUserList];
    var removedUserList = examUserListCopy.splice(index, 1);
    console.log("remove user2", removedUserList);
    setExamUserList(examUserListCopy);
    // add other users
    var otherUserListCopy = [...removedUserList, ...otherUserList];
    setOtherUserList(otherUserListCopy);
  };
  const postData = (e) => {
    e.preventDefault();
    setLoader(true);
    var data = getPostArray();
    console.log("postData", data, id);
    postUserId({ users: data }, id)
      .then((res) => {
        console.log("User Id Post Data", res.data);
        showToast(ToastTypes.success, "User Id added successfully");
        setLoader(false);
        redirect.push(`/admin/exam-sets`);
      })
      .catch((error) => {
        checkAuth(error, redirect);
        setLoader(false);
        showToast(ToastTypes.error, error.response.data.message);
        console.log(error);
      });
  };

  // this index will be from otherUserList
  const addUser = () => {
    var index = selectedUserIndex;
    if (index < 0) return;
    var otherUserListCopy = [...otherUserList];
    selectedUserIndex = -1;
    // remove from other users List
    var addedUserList = otherUserListCopy.splice(index, 1);
    setOtherUserList(otherUserListCopy);
    // add to exam users
    console.log("otherUserList", otherUserListCopy);
    var examUserListCopy = [...examUserList, ...addedUserList];
    setExamUserList(examUserListCopy);
    console.log("examUserList", examUserListCopy, examUserList);
  };

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Contact No.",
        accessor: "contact_no",
      },
    ],
    []
  );
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({ columns, data: examUserList }, useSortBy);
  return (
    <>
      {/* <Header /> */}
      {/* Page content */}
      {/* {student != null ? ( */}
      <Container fluid>
        {loading ? (
          <Spinner />
        ) : (
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0"></CardHeader>

                <Table columns={columns} data={examUserList} />
                <table {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            className={
                              column.isSorted
                                ? column.isSortedDesc
                                  ? "sort-desc"
                                  : "sort-asc"
                                : ""
                            }
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                        <th>Actions</th>
                      </tr>
                    ))}
                  </thead>

                  <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                          <td>
                            <button
                              className=" btn btn-sm bg-danger rounded-pill text-white"
                              onClick={(e) => {
                                console.log(
                                  "removeDum examUserList",
                                  examUserList,
                                  row
                                );
                                removeUser(e, row.index);
                              }}
                            >
                              Remove
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {otherUserList && (
                  <div className="row my-5 align-item-center justify-content-center">
                    <select
                      className="js-example-basic-single col-md-2"
                      value={selectedUserIndex}
                      onChange={(event) => {}}
                    >
                      <option value={-1}>-- Select Student -- </option>
                      {otherUserList.map(function (otherUser, index) {
                        return (
                          <option key={index} value={index}>
                            {otherUser.id + ": " + otherUser.name}
                          </option>
                        );
                      })}
                    </select>
                    <button
                      className="btn bg-success text-white  mx-3 py-0"
                      onClick={addUser}
                    >
                      Add
                    </button>
                  </div>
                )}
                <div className="row align-item-center justify-content-center ">
                  <button
                    className="btn btn-primary px-4 mb-5"
                    onClick={postData}
                  >
                    Save
                  </button>
                </div>
              </Card>
            </div>
          </Row>
        )}
      </Container>
    </>
  );
};

export default ExamSetUserList;
