import React from "react";
import { useMemo, useState, useEffect, useContext } from "react";
import { useTable, useSortBy } from "react-table";
import { AppContext } from "components/Context/ToastContext";
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Spinner,
  CardBody,
} from "reactstrap";
import { checkAuth } from "utils/auth";
import { useHistory, useParams } from "react-router-dom";
import { getExamSets, getPaperDetails } from "services/Api";
import PaperModal from "./PaperModal";
import QuestionModal from "./QuestionModal";
const PaperDetails = () => {
  const [data, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPaperModal, setShowPaperModal] = useState(false);
  const [paper, setPaper] = useState(null);
  const [question, setQuestion] = useState(null);
  const [student, setStudent] = useState();
  const { setLoader } = useContext(AppContext);
  let { id } = useParams();
  const redirect = useHistory();

  useEffect(() => {
    fetchData();
  }, []);
  const openPaperModal = () => {
    setShowPaperModal(true);
  };
  const closeQuestionModal = (refresh) => {
    setQuestion(null);
    if (refresh) fetchData();
  };
  const onPaperModalClosed = (updatedPaper) => {
    setShowPaperModal(false);
    if (updatedPaper != null) setPaper(updatedPaper);
  };
  const fetchData = async () => {
    setLoader(true);
    getPaperDetails(id)
      .then((res) => {
        setTableData(res.data.data.questions);
        setPaper(res.data.data.paper);
        console.log(res.data.data);
        setLoader(false);
      })
      .catch((error) => {
        checkAuth(error, redirect);
        console.log(error);
      });
  };
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "QUESTIONS",
        accessor: "question",
        // Cell: (row) => (
        //   <span
        //     style={{
        //       maxWidth: "800px",
        //       display: "inline-block",
        //     }}
        //   >
        //     {row.row.original.question}{" "}
        //   </span>
        // ),
      },
      {
        Header: "IMAGE",
        Cell: (row) => (
          <div>
            {row.row.original.image_url && (
              <div className="col-md-6">
                <img
                  className=" img-fluid"
                  alt="image"
                  src={row.row.original.image_url}
                  height="150"
                  width="150"
                />
              </div>
            )}
          </div>
        ),
      },
      {
        Header: "ACTIONS",
        Cell: (row) => (
          <div className="table-btn">
            <button
              className="btn btn-sm bg-success rounded-pill ts-btn text-white mr-2"
              onClick={() => setQuestion(row.row.original)}
            >
              Edit
            </button>
          </div>
        ),
      },
    ],
    []
  );
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({ columns, data }, useSortBy);
  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row text-left">
                  <div className="col-md-9">
                    <h3 className="mb-0">{paper && paper.name}</h3>
                  </div>
                  <div className="col-md-3 text-right">
                    <button
                      className="btn bg-success rounded-pill text-white"
                      onClick={openPaperModal}
                    >
                      Change Paper Name
                    </button>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div className="row">
                  <div className="col-md-8 text-left">
                    <h3>Questions</h3>
                  </div>
                  <div className=" col-md-4 text-right">
                    <button
                      className="btn bg-primary text-white btn-sm"
                      onClick={() => setQuestion({})}
                    >
                      New Question
                    </button>
                  </div>
                </div>
              </CardBody>
              {/* <Table columns={columns} data={data} /> */}
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className={
                            column.isSorted
                              ? column.isSortedDesc
                                ? "sort-desc"
                                : "sort-asc"
                              : ""
                          }
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Card>
          </div>
        </Row>
      </Container>
      {paper && (
        <PaperModal
          show={showPaperModal}
          paper={paper}
          close={onPaperModalClosed}
        />
      )}
      <QuestionModal
        paper={paper}
        show={question != null}
        question={question}
        close={closeQuestionModal}
      />
    </>
  );
};

export default PaperDetails;
