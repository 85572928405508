import React from "react";
import { useMemo, useState, useEffect, useContext } from "react";
import { useTable, useSortBy } from "react-table";
import { AppContext } from "components/Context/ToastContext";
import { Card, CardHeader, Table, Container, Row, Spinner } from "reactstrap";
import { checkAuth } from "utils/auth";
import { useHistory } from "react-router-dom";
import { getExamSets, getPapers } from "services/Api";
import PaperModal from "./PaperModal";

const PaperList = () => {
  const [data, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paper, setPaper] = useState(null);
  const [student, setStudent] = useState();
  const { setLoader } = useContext(AppContext);

  const redirect = useHistory();
  const routeChange = () => {
    // redirect.push(`/admin/exam-sets/new`);
  };
  useEffect(() => {
    fetchData();
  }, []);
  const openPaperModal = () => {
    setPaper({});
  };
  const onPaperModalClose = (refresh) => {
    setPaper(null);
    if (refresh) fetchData();
  };
  const fetchData = async () => {
    setLoader(true);
    getPapers()
      .then((res) => {
        setTableData(res.data.data.papers);
        console.log(res.data.data.papers);
        setLoader(false);
      })
      .catch((error) => {
        checkAuth(error, redirect);
        console.log(error);
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "ACTIONS",
        Cell: (row) => (
          <div className="table-btn">
            <button
              className="btn btn-sm bg-success rounded-pill ts-btn text-white   mr-2"
              onClick={() => {
                redirect.push(`papers/${row.row.original.id}/questions`);
                console.log(row.row.original.id);
              }}
            >
              Edit
            </button>
          </div>
        ),
      },
    ],
    []
  );
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({ columns, data }, useSortBy);
  return (
    <>
      {/* <Header /> */}
      {/* Page content */}
      <Container fluid>
        {loading ? (
          <Spinner />
        ) : (
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <div className="row text-left">
                    <div className="col-md-4 col-xs-2">
                      <h3 className="mb-0">Papers</h3>
                    </div>
                    <div className="col text-right">
                      <button
                        className="btn bg-primary text-white btn-sm"
                        onClick={openPaperModal}
                      >
                        New Paper
                      </button>
                    </div>
                  </div>
                </CardHeader>

                <table {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            className={
                              column.isSorted
                                ? column.isSortedDesc
                                  ? "sort-desc"
                                  : "sort-asc"
                                : ""
                            }
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>

                  <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Card>
            </div>
          </Row>
        )}
      </Container>
      <PaperModal
        paper={paper}
        show={paper != null}
        close={(refresh) => onPaperModalClose(refresh)}
      />
    </>
  );
};

export default PaperList;
