import React from "react";
import {
  generateId,
  generateToast,
  ToastTypes,
} from "components/Toast/ToastHelper";
import Toast from "components/Toast/Toast";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { postBatches, postVideoDetails } from "services/Api";
import { checkAuth } from "utils/auth";
import toast from "components/Toast/Toast.css";
import { useHistory, useParams } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "components/Context/ToastContext";

const NewVideoModal = (props) => {
  const [videoDetails, setVideoDetails] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const { setLoader, showToast } = useContext(AppContext);
  let redirect = useHistory();
  const { id } = props;

  const onHandleChanged = (e) => {
    setVideoDetails({ ...videoDetails, [e.target.name]: e.target.value });
  };
  const handleFileSelect = (event) => {
    setSelectedFile(URL.createObjectURL(event.target.files[0]));
    console.log("selected File", selectedFile);
  };
  const postData = (e) => {
    // e.preventDefault();
    setLoader(true);

    var request = { ...videoDetails, setSelectedFile };
    console.log("post Video Details", request, id);

    postVideoDetails(id, request)
      .then((res) => {
        console.log("Video Details Post Data", res.data, id);
        showToast(ToastTypes.success, "Video Details  added successfully");
        setLoader(false);
        onCancelClicked();
      })
      .catch((error) => {
        checkAuth(error, redirect);
        setLoader(false);
        showToast(ToastTypes.error, error.response.data.message);
        console.log(error);
      });
  };
  const onCancelClicked = () => {
    props.close();
  };
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.close}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h3>New Video</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="mb-3 col-5">
                <label htmlFor="subtext" className="form-label">
                  Sub Text
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Chapter 1"
                  value={
                    videoDetails == null || videoDetails.name == null
                      ? ""
                      : videoDetails.name
                  }
                  onChange={onHandleChanged}
                />
              </div>
              <div className="mb-3 col-7">
                <label htmlFor="title" className="form-label">
                  Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  name="description"
                  placeholder="Introduction to Finance"
                  value={
                    videoDetails == null || videoDetails.description == null
                      ? ""
                      : videoDetails.description
                  }
                  onChange={onHandleChanged}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-12">
                <label htmlFor="url" className="form-label">
                  Vimeo Url
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="url"
                  name="url"
                  value={
                    videoDetails == null || videoDetails.url == null
                      ? ""
                      : videoDetails.url
                  }
                  onChange={onHandleChanged}
                />
                <small>
                  <b>Format:</b> https://player.vimeo.com/video/&lt;video-id&gt;
                  <div style={{ width: "30px", display: "inline-block" }}></div>
                  <b>Example:</b> https://player.vimeo.com/video/658103333
                </small>
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-4">
                <label htmlFor="duration" className="form-label">
                  Duration
                </label>
                <div className="d-flex">
                  <input
                    type="number"
                    className="form-control"
                    id="duration"
                    name="duration"
                    style={{ width: "5rem" }}
                    value={
                      videoDetails == null || videoDetails.duration == null
                        ? ""
                        : videoDetails.duration
                    }
                    onChange={onHandleChanged}
                  />
                  <span className="mt-2 ml-2">minutes</span>
                </div>
              </div>
              <div className="mb-3 col-8">
                <label htmlFor="thumbnail" className="form-label">
                  Thumbnail
                </label>
                <input
                  type="file"
                  className="form-control-file"
                  id="thumbnail"
                  name="thumbnail"
                  aria-describedby="fileHelp"
                  accept="image/*"
                  onChange={handleFileSelect}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => onCancelClicked()}
          >
            Cancel
          </button>
          <Button
            className="btn btn-primary success"
            onClick={() => postData()}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NewVideoModal;
